import React from 'react'
import { useSelector } from 'react-redux'
import { TimeLine, timeline$ } from '../../app/lib/timeline/timeline'
import { selectSpeed } from '../../app/store/app/app.state'
import { GameDisplay } from '../game-display/gameDisplay'
import { ProgressBar } from '../progress-bar/progressBar'
import { Container } from './gameHeader.style'

export const GameHeader = () => {
  const speed = useSelector(selectSpeed)
  const [timeline, setTimeline] = React.useState<TimeLine>()

  React.useEffect(() => {
    const sub = timeline$.subscribe(setTimeline)
    return () => sub.unsubscribe()
  }, [speed])

  return (
    <Container>
      <GameDisplay timeline={timeline || ({} as TimeLine)} speed={speed} />
      <ProgressBar progress={timeline?.clock || 0} speed={speed} />
    </Container>
  )
}
