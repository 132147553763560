import styled, { css } from 'styled-components'

export const NotifWrapper = styled.div<{ color?: string; isToast?: boolean }>`
  ${({ theme, color, isToast }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    padding: 6px 10px 10px 10px;
    border-radius: 3px;
    position: relative;
    background: ${theme.colours.surfaces.S050G};

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    ${isToast &&
    css`
      border: 2px solid ${theme.colours.neutrals.N040};
    `};

    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 6px;
      height: 100%;
      background: ${color || 'transparent'};
      border-radius: 3px 0px 0px 3px;
    }

    .icon-arrow {
      cursor: pointer;

      path {
        fill: ${theme.colours.neutrals.N030};
      }
    }

    .content-box {
      display: flex;
      align-items: center;
      width: 100%;

      .msg {
        font-size: 12px;
        font-weight: 500;
        padding: 0 15px;
        color: ${theme.colours.neutrals.N030};
      }

      .dot {
        position: absolute;
        right: 1px;
        top: 1px;
        width: 8px;
        height: 8px;
        background-color: ${theme.colours.actionsPrimary.AP050};
        border-radius: 4px;
        border: 1px solid #515164;
      }
    }

    .date {
      flex-shrink: 0;
      font-size: 10px;
      margin-left: auto;
      color: ${theme.colours.neutrals.N040};
    }

    .icon {
      position: relative;

      path {
        fill: ${theme.colours.neutrals.N040};
      }
    }

    .btnMsg {
      width: 80px;
      height: 32px;
      background: linear-gradient(180deg, #ffc959 0%, #f6b52d 100%);
      border: 0.7px solid #f7bf4a;
      box-shadow: 0px 3px 0px #b5892e, 0px 6px 3px rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      font-size: 12px;
      color: #35383d;
      font-weight: 900;
    }
  `}
`
