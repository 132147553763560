import { motion } from 'framer-motion'
import styled, { css } from 'styled-components'

export const Odo = styled.div`
  width: 2em;
  height: 2.2em;
  overflow: hidden;
`

export const OdoSlider = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OdoNum = styled.div<{ won: boolean }>`
  ${({ won, theme }) =>
    css`
      font-family: 'Roboto Condensed';
      font-size: ${won ? '12px' : '16px'};
      font-weight: 700;
      line-height: 26px;
      color: ${won
        ? theme.colours.surfaceText.ST000
        : theme.colours.neutrals.N030};
    `}
`

export default Odo
