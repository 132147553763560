import { useSelector } from 'react-redux'
import { selectToWin } from '../../app/store/betslip/betslip.selectors'
import { Wrapper } from './winBar.style'

export const WinBar = () => {
  const toWin = useSelector(selectToWin)
  return (
    <Wrapper>
      <span className="heading">Potential Win</span>
      <span className="winAmount">{toWin.toLocaleString()}</span>
    </Wrapper>
  )
}
