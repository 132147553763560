import { useSelector } from 'react-redux'
import { SimBet } from '../../app/adapter/types'
import { selectBets } from '../../app/store/betslip/betslip.selectors'
import { SelectionItem } from '../selectionItem/selectionItem'

export const Selection = () => {
  const selections = useSelector(selectBets)

  return (
    <div data-testid="selection">
      {selections.map((bet: SimBet) => (
        <SelectionItem key={bet.b9jBet} bet={bet} />
      ))}
    </div>
  )
}
