import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  fetchSportsBook,
  selectSelectedMarket,
  selectSportsBook,
} from '../../app/store/sportsbook/spostbook.state'
import { StyledPage } from '../../style/styles'
import { ExploreMenu } from '../explore-menu/explore-menu'
import { ExploreItem } from '../explore/exploreItem'
import { selectRedirect, updateRedirect } from '../../app/store/app/app.state'
import styled from 'styled-components'
import { ExploreHeading } from '../exploreHeading/exploreHeading'
import { ErrorModal } from '../error-modal/errorModal'
import { useAppDispatch } from '../../app/store/store'

export const ExplorePage = () => {
  const simbets = useSelector(selectSportsBook)
  const redirect = useSelector(selectRedirect)
  const dispatch = useAppDispatch()
  const selected = useSelector(selectSelectedMarket)

  useEffect(() => {
    dispatch(fetchSportsBook())
  }, [dispatch, selected])

  if (redirect) {
    dispatch(updateRedirect(null))
  }

  if (simbets.length === 0) {
    return (
      <>
        <ErrorModal />
        <StyledPage>
          <ExploreMenu />
          <MessageWrapper>
            <p data-testid="message">
              There is currently no information to display here.
            </p>
          </MessageWrapper>
        </StyledPage>
      </>
    )
  }

  return (
    <>
      <ErrorModal />
      <StyledPage>
        <ExploreMenu />
        <ExploreHeading />
        <ExploreList data-testid="explore-list">
          {simbets.map((bet) => (
            <ExploreItem bet={bet} key={bet.b9jBet} />
          ))}
        </ExploreList>
      </StyledPage>
    </>
  )
}

const ExploreList = styled.ul`
  overflow: auto;
  margin: 8px 4px;
`
const MessageWrapper = styled.div`
  color: #fff;
  margin: 0 auto;
`
