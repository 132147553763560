import { getSport, SportsCode } from './sportMap'
import { simulateMarkets } from '../markets/marketMap'
import { Outcome } from '../markets/types'
import * as Sim from '../markets'
import { PostMessage, PostMessageSelection, SimBet, Source } from './types'

export const getSymbolToUse = (src: Source) => {
  if (src === 'desktop' || src === 'old-mobile' || src === 'self-service')
    return '#'

  return '_'
}

export const adapter = (pm: PostMessage): SimBet[] => {
  return pm.selections
    .map((sel) => createSelection(sel, pm.source || 'new-mobile'))
    .filter((s) => s.event)
}

export const createSelection = (
  { event, selection, odd, market, sign }: PostMessageSelection,
  src: Source
): SimBet => {
  // get the symbol to split on
  const symbol = getSymbolToUse(src)

  // extract matchId and sport head, MKT, outcome
  const [head, MKT, outcome] = selection.split(symbol)
  const [matchId, sport] = head.split('$')

  // find which sport map to use
  const sportMap = getSport(src, sport as SportsCode)
  if (!sportMap) {
    return {} as SimBet
  }

  const { sportId, marketMap, outcomeMap } = sportMap

  const simulateMarket = marketMap.get(MKT) // find if there is a matching market in the map
  const supported = marketMap.has(MKT) // find if market is supported
  const simMarketID =
    simulateMarkets.get(simulateMarket || ('' as Sim.Market))?.id || 0 // used for backend call
  const matchID = Number(matchId) // cast matchId
  odd = typeof odd === 'string' ? parseFloat(odd) : odd // cast odd adn replace
  const simOutcome = simulateMarket ? outcomeMap(MKT, outcome) : ('' as Outcome) // find the outcome

  // return a valid simulate object
  return {
    b9jBet: selection,
    matchID,
    sport: sportId,
    odd,
    event,
    market,
    simMarketID,
    outcome: sign,
    simOutcome,
    supported,
    blocking: false,
  }
}
