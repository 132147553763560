import styled from 'styled-components'

type PercentBarProps = {
  min: number
  max: number
  value: number
  label: string
}
export const PercentBar: React.FC<PercentBarProps> = ({
  min = 0,
  max = 100,
  value = 0,
  label,
}) => {
  const calculateProgress = ({
    min,
    max,
    value,
  }: Omit<PercentBarProps, 'label'>) => {
    const progress = value - min
    const offsetMax = max - min

    if (max <= 0 || min < 0 || progress <= 0) {
      return 0
    }

    if (progress > offsetMax) {
      return 100
    }
    return Math.round((progress / offsetMax) * 100)
  }

  return (
    <ProgressBar role="progressbar" aria-label={label}>
      <Bar progress={calculateProgress({ min, max, value })} />
    </ProgressBar>
  )
}

const ProgressBar = styled.div`
  background: ${(props) => props.theme.colours.neutrals.N055};
  border-radius: 120px;
  height: 4px;
`

const Bar = styled.div<{ progress: number }>`
  background-color: ${(props) => props.theme.colours.actionsPrimary.AP050};
  background-image: ${(props) => props.theme.colours.actionsPrimary.AP051G};
  border-radius: 4px;
  height: 100%;
  width: ${({ progress }) => `${progress}%`};

  animation: load 1s ease;

  @keyframes load {
    0% {
      width: 0;
    }
    100% {
      width: width;
    }
  }
`
