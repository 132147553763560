import {
  configureStore,
  ThunkAction,
  Action,
  getDefaultMiddleware,
} from '@reduxjs/toolkit'
import appReducer from './app/app.state'
import betslipReducer from './betslip/betslip.state'
import mygamesReducer from './mygames/mygames.state'
import gameReducer from './game/game.state'
import sportsBookReducer from './sportsbook/spostbook.state'
import reportsReducer from './reports/reports.state'
import receiptReducer from './receipt/receipt.state'
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'

const appPersistConfig = {
  key: 'app',
  storage: storage,
  whitelist: [
    'acceptOdds',
    'speed',
    'acceptConditions',
    'isCashierSpeed',
    'canPrint',
  ],
}

export const store = configureStore({
  reducer: {
    app: persistReducer(appPersistConfig, appReducer),
    betslip: betslipReducer,
    mygames: mygamesReducer,
    game: gameReducer,
    sportsbook: sportsBookReducer,
    reports: reportsReducer,
    receipt: receiptReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
export type AppDispatch = typeof store.dispatch

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector
