import { ButtonWrapper, Info, Head, OddButton } from './exploreHead.style'
import { useDispatch, useSelector } from 'react-redux'
import { titleCaseSentence, showOutcome } from '../../app/lib/utils/uiUtils'
import { clicksOnExploreItem } from '../../app/store/betslip/betslip.state'
import { selectBets } from '../../app/store/betslip/betslip.selectors'
import { SimBet } from '../../app/adapter/types'
import { FC } from 'react'
import { ChartBar, CircleAdd, CircleCheck } from '../svgs'
import * as Sim from '../../app/markets'

interface ExploreHeadProps {
  bet: SimBet
  expand: boolean
  setExpand(expand: boolean): void
}

export const ExploreHead: React.FC<ExploreHeadProps> = ({
  bet,
  expand,
  setExpand,
}) => {
  return (
    <>
      <Head key={bet.b9jBet} data-testid="exploreHead" expand={expand}>
        <Info onClick={() => setExpand(!expand)}>
          <span className="market-name">
            {showOutcome(bet.outcome as Sim.Outcome, bet.event)}{' '}
          </span>
          <span className="market"> {bet.market}</span>

          <div className="box">
            <ChartBar width="16" height="16" viewBox="0 0 24 24" />
            <span className="event">{titleCaseSentence(bet.event)}</span>
          </div>
        </Info>
        <Buttons bet={bet} expand={expand} />
      </Head>
    </>
  )
}

interface ButtonProps {
  bet: SimBet
  expand: boolean
}
const Buttons: FC<ButtonProps> = ({ bet, expand }) => {
  const dispatch = useDispatch()
  const betslip = useSelector(selectBets)
  const isSelected = betslip.some((b) => b.b9jBet === bet.b9jBet)

  return (
    <ButtonWrapper>
      <OddButton
        disabled={bet.odd < 0}
        key={bet.b9jBet}
        selected={isSelected}
        onClick={() => dispatch(clicksOnExploreItem(bet))}
        data-testid="explore-head-button"
        expand={expand}
      >
        {bet.odd > 0 && <span className="value">{bet.odd}</span>}
        <span className="add-icon">
          {isSelected ? (
            <CircleCheck width="16" height="16" viewBox="0 -2 24 24" />
          ) : (
            <CircleAdd width="16" height="16" viewBox="0 -2 24 24" />
          )}
        </span>
      </OddButton>
    </ButtonWrapper>
  )
}
