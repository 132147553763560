import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getEnv } from '../../app/envs'
import {
  selectStake,
  selectBlocking,
  selectFreeBetAmount,
} from '../../app/store/betslip/betslip.selectors'
import {
  clearFreeBet,
  clicksInput,
} from '../../app/store/betslip/betslip.state'
import { Gift, Remove } from '../svgs'
import { InputWrapper } from './inputStake.style'

export const InputStake = () => {
  const dispatch = useDispatch()
  const stake = useSelector(selectStake)

  const blocked = useSelector(selectBlocking)
  const freeBetAmount = useSelector(selectFreeBetAmount)

  const openCloseKeyBoard = () => {
    if (!blocked && !freeBetAmount) {
      dispatch(clicksInput())
    }
  }

  const handleRemove = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation()
    dispatch(clearFreeBet())
  }

  return (
    <InputWrapper
      tabIndex={0}
      onClick={openCloseKeyBoard}
      data-testid="input-field"
    >
      {freeBetAmount ? (
        <div className="icon">
          <Gift width="20" height="16" viewBox="3 1 20 20" />
        </div>
      ) : (
        <div className="currency">{getEnv().currency}</div>
      )}

      <div className={`stake ${freeBetAmount ? 'stakeFreeBet' : ''}`}>
        {stake ? stake : 'Enter Amount'}
        <span className="blink" />
      </div>

      {stake && (
        <Remove
          onClick={handleRemove}
          width="20"
          height="20"
          viewBox="0 0 24 24"
          data-testid="input-field-remove"
        />
      )}
    </InputWrapper>
  )
}
