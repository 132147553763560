import { StyledCouponBet } from './couponBet.style'
import { BetListSelection } from '../../app/api/types'
import { findMarketFromID } from '../../app/markets/marketUtils'
import {
  showOutcome,
  showMarket,
  titleCaseSentence,
} from '../../app/lib/utils/uiUtils'

interface CouponBetProps {
  selection: BetListSelection
  idx: number
}
export const CouponBet: React.FC<CouponBetProps> = ({ selection, idx }) => {
  const {
    event_name,
    selected_option,
    market_id,
    odd,
    full_time_result,
    half_time_result,
  } = selection
  return (
    <StyledCouponBet idx={idx} data-testid="coupon-bet">
      <div>
        <div className="coupon-bet">
          <span className="emphasis">
            {showOutcome(selected_option, event_name)}
          </span>
          <span>{showMarket(findMarketFromID(market_id))}</span>
        </div>
        <div>
          <span>{titleCaseSentence(event_name)}</span>
        </div>
      </div>
      <div className="results">
        <div className="odd">{odd}</div>
        <span>
          ({half_time_result}) {full_time_result}
        </span>
      </div>
    </StyledCouponBet>
  )
}
