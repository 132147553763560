import React from 'react'
import { BetListObject } from '../../app/api/types'
import { getEnv } from '../../app/envs'
import { fixDateForAllBrowsers } from '../../app/lib/utils/uiUtils'
import { Gift } from '../svgs'
import { Row, Head, Win, WinAmount } from './couponHead.style'

interface CouponHeadProps {
  bet: BetListObject
  expand: boolean
}
export const CouponHead: React.FC<CouponHeadProps> = ({ bet, expand }) => {
  const { placement_date, win, status, stake, free_bet, free_bet_stake } = bet
  const fixedDate = fixDateForAllBrowsers(placement_date)
  const date = new Date(fixedDate)
  const localDate = date.toLocaleDateString()
  const localTime = date.toLocaleTimeString()

  return (
    <Head expand={expand} data-testid="coupon-head">
      <Row>
        <span className="local-date">
          {localTime} {localDate}
        </span>
        <span className="win-amount">
          {free_bet ? (
            <span>
              <Gift width="11" height="9" fill="#AEB4B9" viewBox="1 1 20 20" />
              Freebet {getEnv().currency} {free_bet_stake}
            </span>
          ) : (
            <>
              {' '}
              {getEnv().currency} {stake}
            </>
          )}
        </span>
      </Row>
      <Row>
        <Win>
          <svg width="12" height="12" viewBox="2 0 17 17">
            <filter id="shadow" x="-40%" y="-40%" width="200%" height="200%">
              <feDropShadow
                dx="0"
                dy="0"
                stdDeviation="2"
                floodColor="#85e9b0"
              />
            </filter>
            <circle
              cx="8"
              cy="50%"
              r="5"
              fill={status > 0 ? '#81f8b4' : '#F07575'}
              style={{ filter: status > 0 ? 'url(#shadow)' : undefined }}
            />
          </svg>
          <span>{status > 0 ? 'Won' : 'Lost'}</span>
        </Win>
        <WinAmount win={win}>
          {status > 0 ? `₦ ${win.toLocaleString()}` : '-'}
        </WinAmount>
      </Row>
    </Head>
  )
}
