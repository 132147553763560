import React from 'react'
import { Odometer } from '../odometer/odometer'
import { BrandSimulate } from '../svgs'
import { ScoreDisplay, Team } from './gameItem.style'

import { splitTeams } from '../../app/lib/utils/uiUtils'

type ScoreBarProps = {
  score: number[]
  halftimeResult: number[]
  event: string
  won: boolean | string
}
export const ScoreBar: React.FC<ScoreBarProps> = React.memo(
  ({ score, halftimeResult, event, won }) => {
    const teams = splitTeams(event)
    return (
      <ScoreDisplay won={!!won}>
        <Team won={!!won}>{teams.team1}</Team>

        <div className="score">
          <Odometer score={score[0]} won={won} />
        </div>
        <span className="results">({halftimeResult[0]})</span>
        <div className="logo">
          <BrandSimulate width="14" height="14" viewBox="-2 -2 28 28" />
        </div>
        <span className="results">({halftimeResult[1]})</span>
        <div className="score">
          <Odometer score={score[1]} won={won} />
        </div>

        <Team left won={!!won}>
          {teams.team2}
        </Team>
      </ScoreDisplay>
    )
  }
)

ScoreBar.displayName = 'ScoreBar'
