import styled from 'styled-components'

export const StyledPage = styled.main`
  /* margin: auto; */
  /* overflow: scroll; */
  display: flex;
  flex-direction: column;
  min-width: 320px;
  max-width: 1024px;
  height: calc(100vh - 63px);
  background-color: ${({ theme }) => theme.colours.surfaces.S000};
  position: relative;
`

export const PageWrapper = styled(StyledPage)`
  height: 100vh;
  overflow: auto;
`
export const Layer = styled.div`
  position: fixed;
  height: 100vh;
  max-width: 1024px;
  min-width: 320px;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
`

export const TransparentLayer = styled(Layer)`
  background-color: #272930;
  z-index: 200;
  opacity: 0.7;
`

export const StyledBody = styled.div`
  position: relative;
  min-height: 100vh;
  top: 8px;
`
