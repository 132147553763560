import { Source, TExploreApi } from '../adapter/types'
import {
  errorHandling,
  statusHandling,
  switchOnFilter,
  switchOnSource,
} from './utils'
import {
  AuthenticateResponse,
  AuthenticateRequest,
  BetListResponse,
  BetRequest,
  BetResponse,
  ReportResponse,
} from './types'
import { targetUrl } from '../envs'
import { FilterList } from '../store/mygames/mygames.state'

/**
 * POST to authenticate unsingcredentials passed down by bet9ja in PostMessage,
 * Returns a session ID
 * @param otp one time password
 * @param novaToken valid token
 */
export const postAuthenticate = async (
  otp: string,
  source: Source
): Promise<AuthenticateResponse> => {
  const payload: AuthenticateRequest = {
    client_token: otp,
    source: switchOnSource(source),
  }

  const response = await fetch(`${targetUrl()}/simulate/Authenticate`, {
    method: 'post',
    headers: {
      accept: 'application/json',
      'content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  }).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}

/**
 * POST a simulate object to retreive a Simulation Data,
 * @param data Formated for the backend request
 */
export const postBet = async ({
  selections,
  stake,
  accept_odds_changes,
  free_bet_token,
  source,
  sessionID,
}: BetRequest): Promise<BetResponse> => {
  const payload = {
    selections,
    stake,
    accept_odds_changes,
    free_bet_token,
    source: switchOnSource(source),
  }

  const response = await fetch(`${targetUrl()}/simulate/Bet`, {
    method: 'post',
    headers: {
      accept: 'application/json',
      'content-Type': 'application/json',
      'X-SID': `${sessionID}`,
    },
    body: JSON.stringify(payload),
  }).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return statusHandling(await response?.json())
}

/**
 * GET: retreive every bets placed by the user,
 * @param nexttoken token used for next page
 * @param sessionID user session ID
 * @param filter fetch won = 1, lost = -1, or both = 0
 */
export const getBetlist = async (
  nexttoken: string,
  sessionID: string,
  filter: FilterList
): Promise<BetListResponse> => {
  const response = await fetch(
    `${targetUrl()}/simulate/BetList?nexttoken=${nexttoken}&filter=${switchOnFilter(
      filter
    )}`,
    {
      method: 'get',
      headers: {
        accept: 'application/json',
        'content-Type': 'application/json',
        'X-SID': `${sessionID}`,
      },
    }
  ).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}

/**
 * POST: create Report,
 * @param range  today = 1, yesterday = 2, last seven days = 3
 * @param sessionID user session ID
 */

export const getReports = async (
  range: number,
  sessionID: string
): Promise<ReportResponse> => {
  const response = await fetch(`${targetUrl()}/simulate/BetReport`, {
    method: 'post',
    headers: {
      accept: 'application/json',
      'content-Type': 'application/json',
      'X-SID': `${sessionID}`,
    },
    body: JSON.stringify({ report_range: range }),
  }).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}

export const getExploreMarket = async (
  sessionID: string,
  marketID: number
): Promise<TExploreApi> => {
  const response = await fetch(
    `${targetUrl()}/api/explore/market/${marketID}`,
    {
      method: 'get',
      headers: {
        accept: 'application/json',
        'X-SID': `${sessionID}`,
      },
    }
  ).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}

export const getExploreSport = async (
  sessionID: string,
  sport: string
): Promise<TExploreApi> => {
  const response = await fetch(`${targetUrl()}/api/explore/${sport}`, {
    method: 'get',
    headers: {
      accept: 'application/json',
      'X-SID': `${sessionID}`,
    },
  }).catch((e) => {
    errorHandling(e)
  })

  if (!response?.ok) {
    errorHandling(await response?.json(), response?.status)
  }

  return await response?.json()
}
