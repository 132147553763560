import styled from 'styled-components'

export const NavWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;
`
export const NavItem = styled.div`
  flex: 1 0 0%;
  text-align: center;
  padding: 15px 0;
  color: ${(props) => props.theme.colours.neutrals.N030};

  &.active,
  &:hover {
    position: relative;
    font-weight: 700;
    color: ${(props) => props.theme.colours.neutrals.N030};
    cursor: pointer;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 2px;
      width: 100%;
      background: ${(props) => props.theme.colours.neutrals.N030};
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }
  }
`
