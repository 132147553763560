import styled from 'styled-components'

export const Box = styled.div`
  display: inline-block;
  vertical-align: middle;
  max-height: 23px;
`

export const HiddenCheckBox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

export const VisibleCheckBox = styled.div<{ checked: boolean }>`
  display: inline-block;
  width: 24px;
  height: 24px;
  border: 1px solid #aeb4b9;
  background-color: #202225;
  border-radius: 3px;

  svg {
    fill: none;
    stroke: #aeb4b9;
    stroke-width: 4;
  }
`

export const Label = styled.span`
  margin-right: 8px;
  font-size: 12px;
  color: #aeb4b9;
`
