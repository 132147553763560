import styled from 'styled-components'

export const Navigation = styled.ul<{ count: number }>`
  margin: 0;
  color: ${(props) => props.theme.colours.neutrals.N030};
  box-sizing: border-box;
  position: relative;
  z-index: 80;
  display: grid;
  /* grid-gap: 16px; */
  grid-template-columns: ${({ count }) =>
    `repeat(${count}, minmax(max-content, 1fr))`};
  width: min-content;
  margin: auto;
  padding: 1px 0;
`

const getSliderPosition = (i = 0, count = 1) => (100 / count) * i

export const Slider = styled.div<{ count: number; selectedIndex: number }>`
  position: absolute;
  width: calc(${({ count }) => 100 / count}% - 32px);
  height: 0;
  border-bottom: ${({ theme }) => `2px solid ${theme.colours.neutrals.N040}`};
  left: ${({ count, selectedIndex }) =>
    `calc(${getSliderPosition(selectedIndex, count)}% + 16px )`};
  transition: left 0.2s ease-in-out;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
  bottom: 3px;
`

export const NavItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 6px 16px 10px;
  list-style-type: none;
  cursor: pointer;

  svg {
    margin-bottom: 2px;
  }

  .title {
    font-size: 10px;
    line-height: 12px;
  }
`

export const StyledNavItem = styled(NavItem)`
  background-image: ${({ theme }) => theme.colours.actionsPrimary.AP050G};
  color: ${({ theme }) => theme.colours.surfaceText.ST100};
  padding-top: 3px;
  padding-bottom: 6px;
  border: none;
  border-radius: 3px;

  .odds {
    font-size: 10px;
    font-weight: 400;
    line-height: 9px;
  }

  .sel {
    font-size: 20px;
    line-height: 24px;
  }
`
