import styled from 'styled-components'
import { motion } from 'framer-motion'

export const KeyboardWrapper = styled(motion.div)`
  height: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colours.surfaces.S090};
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;

  .delete-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
export const Button = styled.button<{ width?: string }>`
  width: ${({ width }) => width};
  height: 45px;
  margin: 0;
  padding: 0;
  border: none;
  user-select: none;
  font-size: 1.1rem;
  color: ${({ theme }) => theme.colours.neutrals.N030};
  background-color: ${({ theme }) => theme.colours.surfaces.S040};
  border-right: 1px solid ${({ theme }) => theme.colours.surfaces.S090};
  border-bottom: 1px solid ${({ theme }) => theme.colours.surfaces.S090};

  &:active {
    background-color: ${({ theme }) => theme.colours.surfaces.S050};
    color: ${({ theme }) => theme.colours.neutrals.N040};
    box-shadow: none;
  }

  &:focus {
    outline: 0px solid ${({ theme }) => theme.colours.actionsPrimary.AP050};
  }

  &:hover {
    cursor: pointer;
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colours.surfaces.S050};
    color: ${({ theme }) => theme.colours.neutrals.N050};
    box-shadow: none;
  }
`
