import { motion } from 'framer-motion'
import { Soccer } from '../svgs'
import { Text, Back, Ball, Letter } from './goal.style'

const goal = {
  visible: (i: number) => ({
    y: -30,
    width: '45%',
    opacity: 0,
    transition: {
      y: {
        delay: i * 0.26,
      },
      width: {
        delay: 0.84,
        duration: 0.3,
      },
      opacity: {
        delay: 0.86,
      },
    },
  }),
  hidden: {
    y: 12,
  },
}

const ball = {
  x: '95vw',
  rotate: 720,
  opacity: [1, 1, 0.9, 0],
  transition: {
    type: 'spring',
    stiffness: 45,
  },
}

export const Goal = () => (
  <>
    <Back
      initial={{
        backgroundColor: '#fff',
      }}
      animate={{
        backgroundColor: '#000',
      }}
      transition={{
        duration: 0.1,
        repeat: 1,
        repeatDelay: 0.9,
        stiffness: 70,
      }}
    />
    <Text>
      {['G', 'O', 'A', 'L'].map((letter, i) => (
        <Letter
          custom={i}
          key={i}
          animate="visible"
          initial="hidden"
          variants={goal}
        >
          {letter}
        </Letter>
      ))}
    </Text>
    <Ball>
      <motion.div animate={ball}>
        <Soccer height="50" width="50" viewBox="0 0 24 24" />
      </motion.div>
    </Ball>
  </>
)
