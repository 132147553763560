import { useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Selection } from '../selection/selection'
import { ActionArea } from '../actionArea/actionArea'
import { SelectionsBar } from '../selections-bar/selectionsBar'
import { ToastNotification } from '../toastNotification/toastNotification'
import { StyledPage } from '../../style/styles'
import { Navigate } from 'react-router-dom'
import { selectRedirect, selectView } from '../../app/store/app/app.state'
import { selectFreeBets } from '../../app/store/betslip/betslip.selectors'
import { clearFreeBet } from '../../app/store/betslip/betslip.state'
import { Disclaimer } from '../disclaimer/disclaimer'
import { useSource } from '../../app/hooks/useSource'
import { ErrorModal } from '../error-modal/errorModal'

export const BetSlipPage = () => {
  const dispatch = useDispatch()
  const betslipRef = useRef<HTMLDivElement>(null)
  const redirect = useSelector(selectRedirect)
  const { isCashier } = useSource()
  const freeBetsList = useSelector(selectFreeBets)
  const view = useSelector(selectView)

  if (redirect) {
    return <Navigate to={`/${redirect}`} />
  }

  if (isCashier && freeBetsList.length > 0 && view === 'betslip') {
    dispatch(clearFreeBet())
  }

  return (
    <>
      <ErrorModal />
      <StyledPage>
        <ToastNotification />
        <Disclaimer />
        <SelectionsBar />
        <BetSlipWrapper ref={betslipRef} data-testid="page">
          <Selection />
          <ActionArea betslipRef={betslipRef} />
        </BetSlipWrapper>
      </StyledPage>
    </>
  )
}

export const BetSlipWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-width: 1024px;
  height: 100%;
`
