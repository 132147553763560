import { showOutcome } from '../../app/lib/utils/uiUtils'
import { PercentBar } from './percentBar'
import { Title, Row, TextWrapper, Text, PercentWrapper } from './stats.style'
import * as Sim from '../../app/markets'
import { TExploreStats } from '../../app/adapter/types'

interface StatsProps {
  stats?: TExploreStats[]
  event: string
}

export const Stats: React.FC<StatsProps> = ({ stats, event }) => {
  return (
    <div data-testid="stats">
      <Title>Market simulation stats</Title>
      <ul>
        {stats?.map((stat: TExploreStats) => {
          return (
            <Row key={stat.option} data-testid="statsItems">
              <TextWrapper>
                <Text>{showOutcome(stat.option as Sim.Outcome, event)}</Text>
              </TextWrapper>
              <PercentWrapper>
                <PercentBar
                  min={0}
                  max={100}
                  value={stat.percentage}
                  label={showOutcome(stat.option as Sim.Outcome, event)}
                />
              </PercentWrapper>
            </Row>
          )
        })}
      </ul>
    </div>
  )
}
