import { FreeBet } from '../../app/api/types'
import { getEnv } from '../../app/envs'
import { useNavigate } from 'react-router-dom'
import { useCountDown } from '../../app/lib/count-down/useCountDown'
import { clicksOnFreeBet } from '../../app/store/betslip/betslip.state'
import { Medal } from '../svgs'
import { FreeBetListItem, FreeBetCard } from './freeBetItem.style'
import { useAppDispatch } from '../../app/store/store'
import { ReactNode } from 'react'

export type FreeBetItemProps = {
  freeBet: FreeBet
}
export const FreeBetNoContent: React.FC = () => {
  return (
    <div className="freeBetNoContent">
      <h1 className="heading">Keep playing to be awarded free bets!</h1>
      <Medal height="32" width="24" viewBox="0 0 24 32" />
    </div>
  )
}

export const FreeBetContent: React.FC<FreeBetItemProps> = ({ freeBet }) => {
  const dispatch = useAppDispatch()
  const expiry = useCountDown(freeBet)
  const navigate = useNavigate()

  const handleClickFreeBet = () => {
    if (expiry?.output !== 'EXPIRED') {
      dispatch(clicksOnFreeBet(freeBet))
      navigate('/')
    }
  }
  return (
    <>
      <div className="amount">
        <div className="title">Free Bet</div>
        <div className="currency">
          {getEnv().currency} {freeBet?.amount}
        </div>
      </div>

      <div className="box">
        <div className="expiry">
          Expires in <span className="time">{expiry?.output}</span>
        </div>
        <button
          onClick={handleClickFreeBet}
          className="btn"
          data-testid="btnMsg"
        >
          Use this!
        </button>
      </div>
    </>
  )
}

export const FreeBetItem: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  return (
    <FreeBetListItem data-testid="freeBet-item">
      <FreeBetCard>{children}</FreeBetCard>
    </FreeBetListItem>
  )
}
