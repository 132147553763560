import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { selectPermissions } from '../../app/store/app/app.state'
import { selectActiveSelections } from '../../app/store/betslip/betslip.selectors'
import { AddMore, Bar } from './sectionBar.style'

export const SelectionsBar = () => {
  const navigate = useNavigate()
  const activeSelections = useSelector(selectActiveSelections)
  const permissions = useSelector(selectPermissions)

  return (
    <Bar>
      <div className="selections">
        <span data-testid="activeSelections" className="amount">
          {activeSelections}
        </span>
        <span className="text">Selections</span>
      </div>
      {permissions?.explore === true ? (
        <AddMore
          data-testid="addMore"
          className="add-more"
          onClick={() => navigate('/explore')}
        >
          + Add more
        </AddMore>
      ) : (
        <></>
      )}
    </Bar>
  )
}
