import styled from 'styled-components'

export const Title = styled.p`
  line-height: 16px;
  margin: 0;
`
export const Row = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 4px;
  }
`

export const TextWrapper = styled.div`
  width: 20%;
  margin-right: 8px;
`
export const PercentWrapper = styled.div`
  width: 80%;
`

export const Text = styled.p`
  color: ${(props) => props.theme.colours.neutrals.N040};
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  word-break: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`
