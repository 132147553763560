import styled from 'styled-components'

export const GameWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  /* margin-bottom: 40px; */
  margin-bottom: 80px;
  padding: 5px;
`
export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1024px;
  min-width: 320px;
`
