import React from 'react'
import { useSelector } from 'react-redux'
import { of, queueScheduler, scheduled } from 'rxjs'
import { map, mergeAll, mergeMap } from 'rxjs/operators'
import { SelectionResponse } from '../api/types'
import { timeline$ } from '../lib/timeline/timeline'
import { wait } from '../lib/utils/asyncUtils'
import { formatScore } from '../lib/utils/uiUtils'

import * as Sim from '../markets'
import { simulateMarkets } from '../markets/marketMap'
import { winningBet } from '../markets/marketUtils'
import { selectSpeed } from '../store/app/app.state'

// REDUCER ----------------------------------------------------------------------------
export type GameItemState = {
  score: Sim.Score
  halftimeResult: Sim.Score
  winning: boolean | 'settled'
  won: boolean | 'settled'
  lost: boolean
  goal: boolean
  flare: boolean
}

const initialState: GameItemState = {
  score: [0, 0],
  halftimeResult: [0, 0],
  winning: false,
  won: false,
  lost: false,
  goal: false,
  flare: false,
}

type TisWinning = (score: number[]) => boolean | 'settled'

type Action =
  | { type: 'RESET'; isWinning: TisWinning }
  // TURBO SPEED
  | { type: 'FLARE' }
  | {
      type: 'TURBO_HALF'
      sel: SelectionResponse
      isWinning: TisWinning
      mktType: Sim.TimeSpan
    }
  | { type: 'TURBO_FULL'; sel: SelectionResponse }
  // OTHER SPEEDS
  | { type: 'GOAL' }
  | {
      type: 'UPDATE_SCORE'
      isWinning: TisWinning
      selection: SelectionResponse
      score: string
    }
  | { type: 'NORMAL_HALF'; sel: SelectionResponse }
  | { type: 'NORMAL_FULL'; sel: SelectionResponse }
  | { type: 'WON' }
  | { type: 'LOST' }

function reducer(state: GameItemState, action: Action): GameItemState {
  switch (action.type) {
    // RESET GAME
    case 'RESET':
      return {
        ...initialState,
        winning: action.isWinning([0, 0]),
      }
    // TURBO SPEED
    case 'FLARE':
      return {
        ...state,
        flare: true,
      }
    case 'TURBO_HALF':
      return {
        ...state,
        flare: false,
        score: formatScore(action.sel.half_time_result),
        halftimeResult: formatScore(action.sel.half_time_result),
        winning: action.isWinning(formatScore(action.sel.half_time_result)),
        won:
          (action.mktType === 'HT' && action.sel.result === 1) ||
          action.isWinning(formatScore(action.sel.half_time_result)) ===
            'settled',
      }
    case 'TURBO_FULL':
      return {
        ...state,
        flare: false,
        score: formatScore(action.sel.full_time_result),
        winning: action.sel.result === 1,
        won: action.sel.result === 1,
      }
    // OTHER SPEEDS
    case 'GOAL':
      return { ...state, goal: true }
    case 'UPDATE_SCORE':
      return {
        ...state,
        goal: false,
        score: formatScore(action.score),
        winning: state.lost
          ? false
          : state.won || action.isWinning(formatScore(action.score)),
        won: state.lost
          ? false
          : state.won ||
            action.isWinning(formatScore(action.score)) === 'settled',
      }
    case 'NORMAL_HALF':
      return {
        ...state,
        halftimeResult: formatScore(action.sel.half_time_result),
      }
    case 'NORMAL_FULL':
      return {
        ...state,
        score: formatScore(action.sel.full_time_result),
        winning: action.sel.result === 1,
        won: action.sel.result === 1,
      }
    case 'WON':
      return {
        ...state,
        won: true,
      }
    case 'LOST':
      return {
        ...state,
        lost: true,
      }

    // ERROR OUT
    default:
      throw new Error()
  }
}

// HOOK ----------------------------------------------------------------------------
export function useItemState(
  selection: SelectionResponse,
  marketName: Sim.Market
) {
  const isWinning = winningBet(selection.selected_option, marketName)
  const speed = useSelector(selectSpeed)
  const mktType = simulateMarkets.get(marketName)?.time || 'FT'

  const turbo$ = timeline$.pipe(
    mergeMap(({ clock, boost }) =>
      of(selection).pipe(
        map((sel) => {
          if (boost) {
            dispatch({ type: 'RESET', isWinning })
          }
          if (clock === 45) {
            ;(async () => {
              dispatch({ type: 'FLARE' })
              await wait(450)
              dispatch({
                type: 'TURBO_HALF',
                sel,
                isWinning,
                mktType,
              })
            })()
          }
          if (clock === 89) {
            ;(async () => {
              dispatch({ type: 'FLARE' })
              await wait(350)
              dispatch({
                type: 'TURBO_FULL',
                sel,
              })
            })()
          }
        })
      )
    )
  )

  const events$ = timeline$.pipe(
    mergeMap(({ clock, boost }) =>
      of(selection).pipe(
        map((sel) => {
          if (boost) {
            dispatch({ type: 'RESET', isWinning })
          }
          if (clock === 45) {
            dispatch({ type: 'NORMAL_HALF', sel })
            if (mktType === 'HT') {
              selection.result === 1
                ? dispatch({ type: 'WON' })
                : dispatch({ type: 'LOST' })
            }
          }
          if (clock === 90) {
            dispatch({ type: 'NORMAL_FULL', sel })
          }
        })
      )
    )
  )

  const goalOnTime$ = timeline$.pipe(
    mergeMap(({ clock }) =>
      of(selection.simulation).pipe(
        mergeMap((sim) =>
          // eslint-disable-next-line array-callback-return
          sim.map(({ time, score }) => {
            if (time === clock) {
              ;(async () => {
                dispatch({ type: 'GOAL' })
                await wait(1200)
                dispatch({
                  type: 'UPDATE_SCORE',
                  score,
                  isWinning,
                  selection,
                })
              })()
            }
          })
        )
      )
    )
  )

  const [state, dispatch] = React.useReducer(reducer, initialState)

  React.useEffect(() => {
    const merge$ = scheduled([events$, goalOnTime$], queueScheduler).pipe(
      mergeAll()
    )
    const useWhich = speed !== 'turbo' ? merge$ : turbo$
    const sub = useWhich.subscribe()
    return () => sub.unsubscribe()
  }, [speed, selection, marketName])

  return state
}
