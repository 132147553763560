import { Wrapper } from './report.style'
import { ReportBox } from './reportBox'
import { ReportsFilter } from './reportFilter'
import {
  createReport,
  selectReportsStatus,
} from '../../app/store/reports/reports.state'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { SpinnerLayer } from '../spinner-layer/spinner-layer'
import { StyledBody, StyledPage } from '../../style/styles'
import { ErrorModal } from '../error-modal/errorModal'
import { useAppDispatch } from '../../app/store/store'

export const ReportPage = () => {
  const dispatch = useAppDispatch()
  const status = useSelector(selectReportsStatus)

  useEffect(() => {
    dispatch(createReport())
  }, [dispatch])

  return (
    <>
      <StyledPage>
        <StyledBody>
          <Wrapper>
            <SpinnerLayer status={status} />
            <ReportsFilter />
            <ReportBox />
          </Wrapper>
        </StyledBody>
      </StyledPage>
      <ErrorModal />
    </>
  )
}
