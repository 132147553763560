import * as Sim from '../markets'
// import * as ISol from './iSol/types'
// import * as B9J from './bet9ja/types'

import { B9jSoccerMap, findSoccerOutcome } from './bet9ja/b9jSoccerMap'
import { B9jZoomSoccerMap, findZoomOutcome } from './bet9ja/b9jZoomMap'
import { ISolSoccerMap, findISolOutcome } from './iSol/iSolSoccerMap'
import { Source } from './types'

type Client = {
  b9j: SportDefinition
  isol: SportDefinition
}
export type SportDefinition = {
  name: SportName
  sportId: number
  marketMap: Map<unknown, Sim.Market>
  // TODO CREATE THE UNION OF ALL OUTCOMES TO TYPE
  outcomeMap: (mkt: any, outcome: any) => Sim.Outcome
}
export type SportsCode = 'S' | 'VS'
export type SportName = 'Soccer' | 'Z.Soccer'

export const getSport = (src: Source, sport: SportsCode) =>
  src === 'old-mobile' || src === 'desktop' || src === 'self-service'
    ? Sports.get(sport)?.isol
    : Sports.get(sport)?.b9j

/** Maps sports to logic */
export const Sports = new Map<SportsCode, Client>()

Sports.set('S', {
  b9j: {
    name: 'Soccer',
    sportId: 1,
    marketMap: B9jSoccerMap,
    outcomeMap: findSoccerOutcome,
  },
  isol: {
    name: 'Soccer',
    sportId: 1,
    marketMap: ISolSoccerMap,
    outcomeMap: findISolOutcome,
  },
})

Sports.set('VS', {
  b9j: {
    name: 'Z.Soccer',
    sportId: 1,
    marketMap: B9jZoomSoccerMap,
    outcomeMap: findZoomOutcome,
  },
  isol: {
    name: 'Z.Soccer',
    sportId: 1,
    marketMap: ISolSoccerMap,
    outcomeMap: findISolOutcome,
  },
})
