import { useSelector } from 'react-redux'
import {
  filterReports,
  selectReportsRange,
} from '../../app/store/reports/reports.state'
import {
  FilterWrapper,
  FilterContainer,
  SelectorUnderlay,
  Selector,
} from './report.style'
import { Range } from '../../app/store/reports/reports.state'
import { useAppDispatch } from '../../app/store/store'

export const ReportsFilter = () => {
  const dispatch = useAppDispatch()
  const range = useSelector(selectReportsRange)

  return (
    <FilterWrapper>
      <FilterContainer>
        <SelectorUnderlay range={range} />
        <Selector
          onClick={() => dispatch(filterReports(Range.LASTSEVENDAYS))}
          selected={range === Range.LASTSEVENDAYS}
        >
          This Week
        </Selector>
        <Selector
          onClick={() => dispatch(filterReports(Range.YESTERDAY))}
          selected={range === Range.YESTERDAY}
        >
          Yesterday
        </Selector>
        <Selector
          onClick={() => dispatch(filterReports(Range.TODAY))}
          selected={range === Range.TODAY}
        >
          Today
        </Selector>
      </FilterContainer>
    </FilterWrapper>
  )
}
