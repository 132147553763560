import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { postBet } from '../../api/httpClient'
import {
  APIStatus,
  BetResponse,
  ErrorResponse,
  Incentives,
} from '../../api/types'
import { generateTimeLine, resetTimeLine } from '../../lib/timeline/timeline'
import {
  changeView,
  createError,
  selectSpeed,
  selectView,
} from '../app/app.state'
import {
  selectBetAPIData,
  selectCockpitValues,
  selectFreeBetAmount,
  selectStake,
} from '../betslip/betslip.selectors'
import {
  updateOdds,
  clearFreeBet,
  createIncentives,
  showMenu,
  setFreeBet,
} from '../betslip/betslip.state'
import { AppThunk, RootState } from '../store'
import { omitIncentives, shield } from '../utils'
import { setReceiptData } from '../receipt/receipt.state'

// redux -------------------------------------------------------------------------------

export type GameData = Omit<BetResponse, 'incentives'>

type GameState = {
  status: APIStatus
  data: GameData
  rebet: boolean
  isLoader: boolean
  hasFreeRebet: boolean
}

export const initialState: GameState = {
  status: 'idle',
  data: {} as GameData,
  rebet: false,
  isLoader: false,
  hasFreeRebet: false,
}

const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    createGameData: (state, { payload }: PayloadAction<GameData>) => {
      state.data = payload
      state.status = 'idle'
    },
    resetGameData: (state) => {
      state.data = {} as GameData
      state.rebet = false
    },
    setStatus: (state, { payload }: PayloadAction<APIStatus>) => {
      state.status = payload
    },
    showRebet: (state, { payload }: PayloadAction<boolean>) => {
      state.rebet = payload
    },
    changeLoader: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoader = payload
    },
    handleFreeRebet: (state, { payload }: PayloadAction<boolean>) => {
      state.hasFreeRebet = payload
    },
  },
})

export default gameSlice.reducer

// actions -------------------------------------------------------------------------------

export const {
  createGameData,
  resetGameData,
  setStatus,
  showRebet,
  changeLoader,
  handleFreeRebet,
} = gameSlice.actions

// thunks -------------------------------------------------------------------------------

export const placeBet = (): AppThunk => async (dispatch, getState) => {
  dispatch(setStatus('loading'))
  const state = getState()

  // TODO: make sure freebet to win is included in the check
  const { stake, toWin, activeSelections } = selectCockpitValues(state)
  const isCashier = state.app.isCashier

  try {
    shield(Number(stake), toWin, activeSelections, state.app.source)

    // dispatch(resetGame())
    const data = selectBetAPIData(state)
    const betResponse = await postBet(data)
    dispatch(createGameData(omitIncentives(betResponse)))
    dispatch(createIncentives(betResponse.incentives))

    if (isCashier) {
      dispatch(printReceipt(betResponse))
    }

    isCashier &&
      (await waitForUserToPrint(isCashier, dispatch, state.app.canPrint))

    isCashier && (await waitForFreeBetTicket(dispatch, betResponse?.incentives))

    isCashier &&
      betResponse?.incentives?.free_bets.length > 0 &&
      dispatch(setFreeBet(betResponse?.incentives?.free_bets[0]))

    dispatch(updateOdds(betResponse))
    if (betResponse.free_bet) {
      dispatch(clearFreeBet())
    }

    if (!isCashier || (isCashier && !betResponse?.incentives?.free_bets)) {
      dispatch(startGame(isCashier))
    }
  } catch (e) {
    dispatch(setStatus('error'))
    dispatch(createError(e as ErrorResponse))
  }
}

export const startGame =
  (isCashier: boolean): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()
    const speed = selectSpeed(state)
    const view = selectView(state)

    dispatch(showMenu(false))
    dispatch(showRebet(false))
    if (view === 'betslip') {
      dispatch(changeView('game'))
    }

    resetTimeLine()

    /**
     * subscribe to timeline$ proxy (behaviour subject),
     * to start the clock stream
     */
    generateTimeLine(speed, isCashier).subscribe({
      // next: (n) => console.log(n.boost),
      error: (error) => dispatch(createError(error)),
      complete: () => {
        parent.postMessage(
          {
            data: {},
            type: 'refreshBalance',
          },
          '*'
        )
        dispatch(showMenu(true))
        dispatch(showRebet(true))
      },
    })
  }

const printReceipt =
  (betResponse: BetResponse): AppThunk =>
  (dispatch) => {
    dispatch(
      setReceiptData({
        bet_id: betResponse.bet_id,
        client_id: betResponse.client_id,
        stake: betResponse.stake,
        win: betResponse.win,
        potencial_win: betResponse.stake * betResponse.total_odds,
        total_odds: betResponse.total_odds,
        placement_date: betResponse.placement_date,
        original: true,
        selections: betResponse.selections,
        status: betResponse.status,
      })
    )
  }

const waitForUserToPrint = async (
  isCashier: boolean,
  dispatch: any,
  canPrint: boolean
) => {
  let resolvePromise: (value: unknown) => void

  if (window.matchMedia) {
    const mediaQueryList: MediaQueryList = window.matchMedia('print')
    mediaQueryList.onchange = (e: MediaQueryListEvent) => {
      if (!e.matches) {
        setTimeout(() => {
          dispatch(changeLoader(false))
          resolvePromise('foo')
        }, 1000)
      }
    }
  }

  const myPromise = () => {
    return new Promise((resolve) => {
      resolvePromise = resolve
      if (!canPrint) resolvePromise('foo')
      if (isCashier) {
        canPrint && dispatch(changeLoader(true))

        setTimeout(() => {
          canPrint && window.print()
        }, 2000)
      }
    })
  }

  await myPromise()
}

const waitForFreeBetTicket = async (dispatch: any, incentives: Incentives) => {
  const myPromise = () => {
    return new Promise((resolve) => {
      if (incentives?.free_bets.length > 0) {
        dispatch(handleFreeRebet(true))
      }
      resolve('foo')
    })
  }

  await myPromise()
}

// selectors -----------------------------------------------------------------------

export const selectGameData = (state: RootState) => state.game.data
export const selectIsLoader = (state: RootState) => state.game.isLoader
export const selectHasFreeRebet = (state: RootState) => state.game.hasFreeRebet
export const selectGameAPIStatus = (state: RootState) => state.game.status
export const selectRebet = (state: RootState) => state.game.rebet
export const selectGameSelections = (state: RootState) =>
  state.game.data.selections
export const selectGameDataAccStatus = (state: RootState) =>
  state.game.data.account_status

export const selectGameFooterInfos = createSelector(
  [selectStake, selectView, selectGameData, selectRebet, selectFreeBetAmount],
  (stake, view, { status, free_bet, win, bet_id }, rebet, freeBetAmount) => ({
    stake,
    view,
    bet_id,
    status,
    wasFreeBet: free_bet,
    win,
    visible: rebet,
    freeBetAmount,
  })
)
