import React from 'react'
import styled from 'styled-components'
import { BetListObject } from '../../app/api/types'
import { CouponDetail } from '../couponDetail/couponDetail'
import { CouponHead } from '../couponHead/couponHead'

export const Coupon: React.FC<BetListObject> = (props) => {
  const [expand, setExpand] = React.useState<boolean>(false)

  const openClose = () => setExpand(!expand)

  return (
    <CouponWrapper onClick={openClose} data-testid="coupon">
      <CouponHead expand={expand} bet={props} />
      <CouponDetail expand={expand} bet={props} />
    </CouponWrapper>
  )
}

export const CouponWrapper = styled.div`
  position: relative;
  width: 97%;
  margin-top: 2px;
`
