import styled from 'styled-components'

export const ActionAreaWrapper = styled.div`
  margin-top: auto;
  width: 100%;
  padding: 4px;
  background-color: ${(props) => props.theme.colours.surfaces.S000};
  color: ${(props) => props.theme.colours.neutrals.N030};
  font-size: 12px;
  border-radius: 6px 6px 0px 0px;
`
export const CockpitWrapper = styled.div`
  margin: auto;
  z-index: 20;
  background: ${(props) => props.theme.colours.surfaces.S090};
  margin-top: 4px;
  border-radius: 6px 6px 0px 0px;
`
export const Wrapper = styled.div`
  margin-top: auto;
  display: grid;
  grid-gap: 4px;
  grid-template-rows: 40px;
  grid-template-columns: 0.75fr 1fr;
`

export const AcceptOddsWrapper = styled.div`
  display: grid;
  grid-template-rows: 40px;
  margin-top: auto;
`

export const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
`
