import { Odo, OdoNum, OdoSlider } from './odometer.style'

export const Odometer: React.FC<{ score: number; won: boolean | string }> = ({
  score,
  won,
}) => (
  <Odo data-testid="odometer">
    <OdoSlider animate={{ y: `${score * -10}%` }}>
      {[...Array(10).keys()].map((num) => (
        <OdoNum key={'odo_' + num} won={!!won}>
          {num}
        </OdoNum>
      ))}
    </OdoSlider>
  </Odo>
)
