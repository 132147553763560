import { Source } from '../adapter/types'
import { FilterList } from '../store/mygames/mygames.state'
import { BetResponse, ErrorResponse } from './types'

/**
 * Maps source to Backend number.
 * @param source Application sending the data
 */
export const switchOnSource = (source: Source) => {
  const src: { [key in Source]: number } = {
    'new-mobile': 0,
    'old-mobile': 1,
    desktop: 2,
    'self-service': 3,
    'new-desktop': 4,
    'mobile-lite': 5,
    '': 0,
  }

  return src[source] || 0
}

/**
 * Maps Filter to BackEnd number
 * @param f mygames filter value
 */
export const switchOnFilter = (f: FilterList) => {
  switch (f) {
    case 'lost':
      return -1
    case 'won':
      return 1
    case 'all':
      return 0
  }
}

/**
 * Backend status code handler, returns the appropriate message.
 * @param data the bet response
 */
export const statusHandling = (data: BetResponse): BetResponse | never => {
  const accountStatusDico = {
    '-2': 'Winnings not paid',
    '-1': 'Not enough funds',
    '0': 'No money paid',
    '1': 'Bet paid',
    '2': 'Win paid',
  }

  const betStatusDico = {
    '-10': 'Bet Not placed',
    '-1': 'Lost',
    '0': 'Running',
    '1': 'Won',
  }

  if (data.account_status === -1) {
    throw { message: accountStatusDico[data.account_status] }
  }
  if (data.status === -10) {
    throw { message: betStatusDico[data.status] }
  }
  return data
}

export const errorHandling = (res: any, status = 500): ErrorResponse => {
  const say = (code: number) => {
    const out: { [key: number]: string } = {
      401: 'Session expired.',
      403: 'Unsuccessful login.',
      404: 'Page Not found.',
      408: 'Request Timeout.',
      422: 'Invalid Betslip.',
      424: 'Your selections have changed.',
      425: 'FreeBet is not valid or has expired.',
    }

    return out[code] || 'Oops, a problem occured...'
  }

  const errorResponse: ErrorResponse = {
    message: say(status),
    status: status || undefined,
    data: res || {},
  }

  throw errorResponse
}
