import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getBetlist } from '../../api/httpClient'
import { APIStatus, BetListObject, BetListResponse } from '../../api/types'
import { AppThunk, RootState } from '../store'

export type FilterList = 'won' | 'lost' | 'all'

type MyGamesSlice = {
  status: APIStatus
  list: BetListObject[]
  token: string
  filter: FilterList
  page: number
}

export const initialState: MyGamesSlice = {
  status: 'idle',
  list: [] as BetListObject[],
  token: '',
  filter: 'all',
  page: 0,
}

export const myGamesSlice = createSlice({
  name: 'mygames',
  initialState,
  reducers: {
    createBetList: (state, { payload }: PayloadAction<BetListResponse>) => {
      state.list = state.list.concat(payload?.bet_list)
      state.token = payload?.token
      state.status = 'idle'
    },
    setToken: (state, { payload }: PayloadAction<string>) => {
      state.token = payload
    },
    setStatus: (state, { payload }: PayloadAction<APIStatus>) => {
      state.status = payload
    },
    setFilter: (state, { payload }: PayloadAction<FilterList>) => {
      state.filter = payload
    },
    resetMyGames: (state) => {
      state.list = []
    },
  },
})

export default myGamesSlice.reducer

// actions --------------------------------------------------------

export const { createBetList, setFilter, setToken, setStatus, resetMyGames } =
  myGamesSlice.actions

// thunks --------------------------------------------------------

export const getNextList = (): AppThunk => async (dispatch, getState) => {
  const { status, token } = getState().mygames
  if (status !== 'loading' && token) {
    dispatch(fetchBetList(token))
  }
}

export const filterList =
  (filter: FilterList): AppThunk =>
  async (dispatch, getState) => {
    const { status } = getState().mygames
    if (status !== 'loading') {
      dispatch(setFilter(filter))
      dispatch(resetMyGames())
      dispatch(fetchBetList(''))
    }
  }

export const fetchBetList =
  (token: string): AppThunk =>
  async (dispatch, getState) => {
    const state = getState()

    const { sessionID } = state.app
    const { filter } = state.mygames
    if (token === '') dispatch(resetMyGames())

    dispatch(setStatus('loading'))

    try {
      const response = await getBetlist(token, sessionID, filter)
      dispatch(createBetList(response))
    } catch (e) {
      dispatch(setStatus('error'))
      dispatch(
        createBetList({
          bet_list: [],
          token: '',
        })
      )
    }
  }

// Selectors -------------------------------------------------------

export const selectMyGames = (state: RootState) => state.mygames
export const selectMyGamesStatus = (state: RootState) => state.mygames.status
export const selectMyGamesList = (state: RootState) => state.mygames.list
export const selectNextPageToken = (state: RootState) => state.mygames.token
export const selectMyGamesPage = (state: RootState) => state.mygames.page
export const selectFilter = (state: RootState) => state.mygames.filter
