import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Selection = styled.div<{ onClick?: any }>`
  display: flex;
  align-items: center;
  color: ${(props) => props.theme.colours.neutrals.N040};
  background-color: ${(props) => props.theme.colours.surfaces.S090};
  height: 48px;
  width: 100%;
  padding: 10px 4px;
  letter-spacing: 0.13px;
  z-index: 200;

  &:not(:last-child) {
    border-bottom: 1px solid #32353d;
  }

  &:hover,
  &:active {
    cursor: pointer;
    background-color: ${(props) => props.theme.colours.surfaces.S040};
    color: ${(props) => props.theme.colours.neutrals.N030};
  }
`

export const ButtonContainer = styled.div`
  position: relative;
  border-radius: 2px;
  padding: 0 4px;
  display: flex;
  background-color: ${(props) => props.theme.colours.surfaces.S090};
  svg {
    path {
      fill: ${(props) => props.theme.colours.neutrals.N040};
    }
  }
`

export const SelectionContainer = styled(motion.div)`
  position: absolute;
  top: 44px;
  left: 0;
  right: 0;
  background-color: #3d414b;
  box-shadow: 0px 15px 15px rgba(0, 0, 0, 0.9);
  border-radius: 2px;
  overflow: hidden;
`
