import styled, { keyframes } from 'styled-components'

const keyFrameBlink = keyframes`
  50% {
    opacity: 1;
  }
`
export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  /* flex-basis: 60%; */
  height: 40px;
  padding: 0 8px;
  background: ${(props) => props.theme.colours.surfaces.S000};
  color: ${(props) => props.theme.colours.neutrals.N040};
  box-shadow: inset 0px 1px 0px #121212, inset 0px -1px 0px #2a2a2a;
  border-radius: 3px;
  outline: none;

  flex: 1;
  margin-left: 8px;

  .currency {
    font-size: 16px;
  }

  .stake {
    display: flex;
    align-items: center;
    margin: 0 15px 0 auto;
    color: ${(props) => props.theme.colours.neutrals.N050};
  }

  .stakeFreeBet {
    display: flex;
    align-items: center;
    margin: 0 auto 0px 5px;
    color: ${(props) => props.theme.colours.actionsPrimary.AP050};
  }

  .blink {
    display: inline-block;
    height: 26px;
    border-right: 1px solid transparent;
  }

  .icon {
    path {
      fill: ${(props) => props.theme.colours.actionsPrimary.AP050};
    }
  }

  &:focus {
    .blink {
      opacity: 0;
      border-right-color: #fff;
      animation: ${keyFrameBlink} 1s 0s infinite;
    }
  }
`
