import styled, { css } from 'styled-components'

export const Item = styled.div`
  font-family: 'Roboto Condensed';
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  width: 100%;
  height: 54px;
  border-radius: 3px;
  overflow: hidden;
`

export const ScoreDisplay = styled.div<{ won: boolean }>`
  ${({ won, theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 50%;
    color: ${won
      ? theme.colours.surfaceText.ST000
      : theme.colours.neutrals.N030};
    background: ${won
      ? theme.colours.responsePositive.RP020
      : theme.colours.surfaces.S050G};
  `};

  .logo {
    width: 14px;
    height: 14px;
    margin: 0 5px;
    background-color: ${({ theme }) => theme.colours.surfaces.S000};
    border-radius: 50%;
  }

  .results {
    ${({ won, theme }) => css`
      font-size: ${won ? '12px' : '8px'};
      font-weight: 400;
      color: ${won
        ? theme.colours.surfaceText.ST000
        : theme.colours.neutrals.N030};
    `};
  }
`
export const Team = styled.div<{ won: boolean; left?: boolean }>`
  ${({ won, theme, left }) => css`
    font-weight: 700;
    width: 100%;
    text-align: ${left ? 'left' : 'right'};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: ${won
      ? theme.colours.surfaceText.ST000
      : theme.colours.neutrals.N030};
    text-transform: ${won ? 'unset' : 'uppercase'};
  `};
`

export const Status = styled.div<{ won: boolean }>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50%;
  width: 100%;
  font-size: 12px;
  background-color: ${({ theme }) => theme.colours.information.RI090};

  ${({ won }) =>
    won &&
    css`
      background-color: ${({ theme }) => theme.colours.responsePositive.RP030};
      color: ${({ theme }) => theme.colours.surfaceText.ST100};
    `};

  .outcome {
    ${({ won, theme }) => css`
      font-weight: 500;
      color: ${won
        ? theme.colours.surfaceText.ST000
        : theme.colours.neutrals.N030};
    `}
  }

  .market {
    ${({ won, theme }) => css`
      font-weight: 400;
      margin-left: 4px;
      color: ${won
        ? theme.colours.surfaceText.ST000
        : theme.colours.neutrals.N040};
    `}
  }
`

export const Icon = styled.span`
  display: flex;
  position: absolute;
  width: 23px;
  right: 0px;

  path {
    fill: #fff;
  }
`

export const Ball = styled.div`
  position: absolute;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: flex-start;
  background-color: transparent;
  border-radius: 3px;
  overflow: hidden;
  z-index: 200;
`
