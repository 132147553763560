import { useSelector } from 'react-redux'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { BetSlipPage } from './components/betslipPage/betslipPage'
import { ExplorePage } from './components/explorePage/explorePage'
import { GameLoader } from './components/game-loader/gameLoader'
import { GameLayout } from './components/game-page/game'
import { MyBetsPage } from './components/myBetsPage/myBetsPage'
import { useSource } from './app/hooks/useSource'
import { useStartApp } from './app/hooks/useStartApp'
import { selectPermissions, selectView } from './app/store/app/app.state'
import { FreeBetTicket } from './components/free-betTicket/freeBetTicket'
import { ReportPage } from './components/report-page/reportPage'
import { Receipt } from './components/receipt/receipt'
import { Loader } from './components/loader/loader'
import { NavBarWrapper } from './components/navBarWrapper/navBarWrapper'
import { FreeBetList } from './components/freeBetList/freeBetList'
import { MyBets } from './components/myBets/myBets'
import { DesktopSource } from './app/adapter/types'

const desktop: DesktopSource[] = ['desktop', 'self-service', 'new-desktop']

export default function App() {
  useStartApp()
  const permissions = useSelector(selectPermissions)
  const view = useSelector(selectView)
  const { isCashierAndSelfService, isCashier, source } = useSource()
  const isGameView = view === 'game'
  const isDesktop = desktop.includes(source as DesktopSource)

  return (
    <BrowserRouter>
      {isDesktop && !isGameView && (
        <NavBarWrapper
          isDesktop={isDesktop}
          isGameView={isGameView}
          isCashier={isCashier}
        />
      )}
      <Routes>
        <Route
          path="/"
          element={
            <>
              {isGameView ? <GameLayout /> : <BetSlipPage />}
              <FreeBetTicket />
              {isCashier ? <Loader /> : undefined}
              {isCashier ? <Receipt /> : undefined}
            </>
          }
        />

        {permissions?.explore === true ? (
          <Route path="explore" element={<ExplorePage />} />
        ) : undefined}

        <Route path="mybets" element={<MyBetsPage />}>
          <Route index element={<MyBets />} />
          <Route
            path="freebets"
            element={!isCashier ? <FreeBetList /> : undefined}
          />
        </Route>

        {isCashierAndSelfService && (
          <Route path="report" element={<ReportPage />} />
        )}
      </Routes>

      {!isDesktop && !isGameView && (
        <NavBarWrapper
          isDesktop={isDesktop}
          isGameView={isGameView}
          isCashier={isCashier}
        />
      )}
      <GameLoader />
    </BrowserRouter>
  )
}
