import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  clicksDontShowAgain,
  selectAcceptConditions,
} from '../../app/store/app/app.state'
import { CheckBox } from '../checkbox/checkbox'
import { Modal } from '../modal/modal'
import { LogoSIM, LogoTextSIM } from '../svgs'
import { StyledDisclaimer } from './disclaimer.style'

export const Disclaimer = () => {
  const dispatch = useDispatch()
  const accept = useSelector(selectAcceptConditions)
  const [modal, setModal] = React.useState<boolean>(!accept)

  return (
    <>
      {modal && (
        <Modal width="92vw">
          <StyledDisclaimer>
            <div className="brand">
              <LogoSIM
                width="32"
                height="32"
                viewBox="-4 0 34 34"
                className="icon-logo"
              />
              <LogoTextSIM
                width="100"
                height="12"
                viewBox="29 -1 10 10"
                fill="#F8B133"
              />
            </div>
            <p>
              The result of the selections will be simulated and generated using
              a random number generator. The outcome is not related to the real
              life fixtures.
            </p>
            <CheckBox
              checked={accept}
              onChange={() => dispatch(clicksDontShowAgain())}
              label="Don't show this window again"
            />
            <div style={{ marginTop: '1rem' }} />
            <button
              className="btn-disclaimer"
              onClick={() => setModal((s) => !s)}
            >
              <span>OK Continue</span>
            </button>
          </StyledDisclaimer>
        </Modal>
      )}
    </>
  )
}
