import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import {
  selectActiveSelections,
  selectOdds,
} from '../../app/store/betslip/betslip.selectors'
import { useNavigate } from 'react-router'
import {
  BrandSimulate,
  Compas,
  Avatar,
  AvatarFilled,
  CompasFilled,
  Report,
  ReportFilled,
} from '../svgs'
import { NavItem, Slider, Navigation, StyledNavItem } from './navBar.style'
import { useSource } from '../../app/hooks/useSource'

export const NavBar = () => {
  const [activeItemIndex, setActiveItemIndex] = useState(1)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const activeSelections = useSelector(selectActiveSelections)
  const odds = useSelector(selectOdds)
  const { isCashierAndSelfService } = useSource()

  const getClassNames = (path: string) => {
    let classNames = ''

    if (pathname === path) {
      classNames += ' active'
    }
    return classNames
  }

  const selectActive = (newIndex: number, path: string) => {
    navigate(path)
    setActiveItemIndex(newIndex)
  }

  useEffect(() => {
    switch (pathname) {
      case '/':
        return setActiveItemIndex(1)
      case '/explore':
        return setActiveItemIndex(0)
      case '/mybets':
      case '/mybets/freebets':
        return setActiveItemIndex(2)
      case '/report':
        return setActiveItemIndex(3)
    }
  }, [pathname])

  const links = [
    {
      path: '/explore',
      iconInactive: <Compas width="24" height="24" viewBox="0 0 24 24" />,
      iconActive: <CompasFilled width="24" height="24" viewBox="0 0 24 24" />,
      label: 'Explore',
    },
    {
      path: '/',
      iconActive: <BrandSimulate width="24" height="24" viewBox="0 0 24 24" />,
      label: 'Bet Slip',
    },
    {
      path: '/mybets',
      iconInactive: <Avatar width="24" height="24" viewBox="0 0 24 24" />,
      iconActive: <AvatarFilled width="24" height="24" viewBox="0 0 24 24" />,
      label: 'My Bets',
    },
    {
      path: '/report',
      iconInactive: <Report width="24" height="24" viewBox="0 0 24 24" />,
      iconActive: <ReportFilled width="24" height="24" viewBox="0 0 24 24" />,
      label: 'Report',
    },
  ].filter(
    (link) =>
      link.label !== 'Report' ||
      (link.label === 'Report' && isCashierAndSelfService)
  )

  return (
    <div>
      <Navigation count={links.length}>
        {links.map(({ label, path, iconActive, iconInactive }, index) => {
          if (label === 'Bet Slip' && pathname !== '/') {
            return (
              <StyledNavItem
                key={label}
                onClick={() => selectActive(index, path)}
                className={getClassNames(path)}
                data-testid="navItem"
              >
                <span className="odds">{odds.toFixed(2)}</span>
                <span className="sel">{activeSelections}</span>
                <span className="title">{label}</span>
              </StyledNavItem>
            )
          }

          return (
            <NavItem
              key={label}
              onClick={() => selectActive(index, path)}
              className={getClassNames(path)}
              data-testid="navItem"
            >
              {pathname === path ? iconActive : iconInactive}
              <span className="title">{label}</span>
            </NavItem>
          )
        })}

        <Slider count={links.length} selectedIndex={activeItemIndex} />
      </Navigation>
    </div>
  )
}
