import styled, { css } from 'styled-components'

export const QuickStakesWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0px 13px 8px 13px;
  border-bottom: 1px solid ${({ theme }) => theme.colours.surfaces.S050};

  & button:last-child {
    margin-right: 0;
  }
`

export const Button = styled.button`
  width: 100%;
  height: 40px;
  font-weight: 700;
  margin-right: 4px;
  padding: 12px 1px;
  background: ${({ theme }) => theme.colours.surfaces.S040};
  color: ${({ theme }) => theme.colours.neutrals.N030};
  border-radius: 20px;
  border: none;
  outline: none;
  cursor: pointer;

  &:last-child {
    margin-right: 0;
  }

  &:active {
    background-color: ${({ theme }) => theme.colours.information.RI090};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.colours.surfaces.S050};
    color: ${({ theme }) => theme.colours.neutrals.N050};
    box-shadow: none;
  }
`

export const FreeBetButton = styled(Button)<{
  disabled: boolean
  animation: boolean
}>`
  max-width: 235px;
  white-space: nowrap;
  padding: 8px;
  position: relative;
  overflow: hidden;

  @keyframes flash {
    0% {
      left: -20%;
    }
    50% {
      left: 120%;
    }
    100% {
      left: -20%;
    }
  }

  &:before {
    display: block;
    position: absolute;
    left: 20px;
    top: 0;
    height: 100%;
    background-color: ${({ theme }) => theme.colours.actionsPrimary.AP050};
    transform: skew(330deg, 0deg);
    width: 0px;
    opacity: 0.8;
    box-shadow: 1px 5px 18px 10px rgb(246 181 45);
    animation: flash 2.5s infinite ease-in-out;
    ${({ animation }) =>
      animation
        ? css`
            content: unset;
          `
        : css`
            content: '';
          `}
  }

  path {
    fill: ${({ disabled, theme }) =>
      disabled
        ? theme.colours.neutrals.N050
        : theme.colours.actionsPrimary.AP050};
  }
`

export const FreeBet = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  padding: 0 5px;

  .info {
    margin-left: 5px;
  }
`
