import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background: black;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-image: linear-gradient(180deg, #ffc959 0%, #f6b52d 100%);
  border: 0.7px solid #f7bf4a;
  box-shadow: 0px 3px 0px #b5892e, 0px 6px 3px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  color: ${(props) => props.theme.colours.surfaceText.ST100};
  font-weight: bold;
  font-size: 18px;
  width: 162px;
  height: 54px;
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
    background-image: linear-gradient(#3a3a48 100%, #3a3a48 100%);
    color: #878787;
    box-shadow: none;
    border: none;
  }
`

export const Ticket = styled.div<{ asset: any }>`
  background-image: url(${({ asset }) => asset});
  width: 532px;
  height: 268px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 3;

  .icon-rebet {
    position: absolute;
    top: -24px;
    left: -24px;
    width: 84px;
    height: 84px;
    background: ${(props) => props.theme.colours.actionsPrimary.AP050};
    border: 3px solid #fefdbe;
    box-shadow: 3px 4px 0px rgba(0, 0, 0, 0.13);
    border-radius: 112px;
    transform: rotate(-13.93deg);

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heading_won {
    color: #fff;
    font-size: 31.47px;
    font-weight: bold;
  }

  .heading_rebet {
    color: ${(props) => props.theme.colours.actionsPrimary.AP020};
    text-shadow: 0px 4.65306px 0px rgba(0, 0, 0, 0.09);
    font-weight: bold;
    font-size: 48.51px;
  }

  .heading_info {
    color: #fff;
    font-weight: bold;
    font-size: 22.4681px;
    padding: 6px 0 22px 0;
  }
`
