import { motion } from 'framer-motion'
import styled from 'styled-components'

export const Contain = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-width: 1024px;
`

export const CountDownText = styled(motion.div)`
  font-family: Lcdphone;
  font-size: 4em;
  color: #f59c0d;
  z-index: 500;
  opacity: 1;
  overflow: -moz-hidden-unscrollable;
  overflow: hidden;
`
