const actionsPrimary = {
  //PRIMARY
  AP010: '#F1E3C6',
  AP050: '#F6B52D',
  AP050G: 'linear-gradient(180deg, #FFC959 0%, #F6B52D 100%)',
  AP051G: 'linear-gradient(180deg, #E6C676 0%, #DBA928 100%)',
  AP080: '#F7BF4A',
  AP020: '#FEFDBE',
  AP050O: 'rgba(246, 181, 45, 0.15)',
}

const neutrals = {
  N030: '#DEE2E7',
  N040: '#AEB4B9',
  N050: '#727284',
  N055: '#484A55',
}

const surfaceText = {
  ST000: '#FFFFFF',
  ST100: '#35383D',
}

const surfaces = {
  S000: '#17181C',
  S040: '#595B6A',
  S040G: 'linear-gradient(180deg, #3A3A48 0%, #202227 100%)',
  S050: '#3A3A48',
  S050G: 'linear-gradient(180deg, #444455 0%, #3C3C48 100%)',
  S090: '#202225',
  S090G: 'linear-gradient(180deg, #2D2F34 0%, #202227 100%)',
  SR050: '#79182F',
  SB050: '#7491D2',
  SP050: '#B68ED4',
  S050O: 'rgba(89, 91, 106, 0.5)',
}

const responsePositive = {
  RP050: '#97BD6F',
  RP010: '#81F8B4',
  RP020: '#0FA666',
  RP030: '#3B845A',
}

const responseNegative = {
  RN050: '#F5222D',
  RN080: '#48242F',
}

const information = {
  RI090: '#33333D',
}

export const dark = {
  colours: {
    actionsPrimary,
    neutrals,
    surfaceText,
    surfaces,
    responsePositive,
    responseNegative,
    information,
  },
}
