import { useSelector } from 'react-redux'
import { getEnv } from '../../app/envs'
import {
  selectDisplayStake,
  selectPlaceBet,
} from '../../app/store/betslip/betslip.selectors'
import { closeKeyboard } from '../../app/store/betslip/betslip.state'
import { placeBet } from '../../app/store/game/game.state'
import { useAppDispatch } from '../../app/store/store'
import { Spinner } from '../spinner/spinner'
import { Gift } from '../svgs'
import { Button } from './placeBetButton.style'

export const PlaceBetButton = () => {
  const dispatch = useAppDispatch()
  const stake = useSelector(selectDisplayStake)
  const { disablePlaceBet, sessionID, freeBetAmount } =
    useSelector(selectPlaceBet)

  const handlePlaceBet = () => {
    dispatch(placeBet())
  }

  return (
    <Button
      disabled={disablePlaceBet}
      onClick={handlePlaceBet}
      onFocus={() => dispatch(closeKeyboard())}
      data-testid="placeBetButton"
    >
      {!sessionID ? (
        <Spinner height={'1vh'} size={'35px'} />
      ) : freeBetAmount ? (
        <span className="stake">
          <Gift width="20" height="16" viewBox="5 1 20 20" />
          Place Simulate Free Bet
        </span>
      ) : (
        <p className="stake">
          Place Bet ({getEnv().currency} {stake})
        </p>
      )}
    </Button>
  )
}
