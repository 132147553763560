import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { NavWrapper, NavItem } from './myBetsNav.style'
import { useSource } from '../../app/hooks/useSource'

export const MyBetsNav = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const isActive = (path: string) => (pathname === path ? 'active' : '')
  const { isCashier } = useSource()

  return (
    <NavWrapper>
      <NavItem
        onClick={() => navigate('/mybets')}
        className={isActive('/mybets')}
      >
        My Bets
      </NavItem>
      {!isCashier && (
        <NavItem
          onClick={() => navigate('/mybets/freebets')}
          className={isActive('/mybets/freebets')}
        >
          Free Bets Available
        </NavItem>
      )}
    </NavWrapper>
  )
}
