import { PostMessage } from '../adapter/types'
import { fakeOTPs } from './mockCredentials'

export const newMobilePM: PostMessage = {
  stake: 100,
  otp: fakeOTPs.nofail_d,
  source: 'new-mobile',
  selections: [
    {
      selection: '44109754$VS_OU@1.5_O',
      event: 'Z.Monaco - Z.Reims',
      market: 'Over/Under Goals',
      sign: 'Over (1.5) Goals',
      odd: 1.32,
    },
    {
      selection: '78765$S_1X2_X',
      event: 'Juventus - Napoli',
      market: '1X2',
      sign: 'Draw',
      odd: 3.2,
    },
    {
      selection: '99927928$S_1X2_1',
      event: 'Lazio - Roma',
      market: '1X2',
      sign: 'Lazio',
      odd: 2,
    },
  ],
}

export const newDesktopPM: PostMessage = {
  stake: 100,
  otp: fakeOTPs.nofail_d,
  source: 'new-desktop',
  selections: [
    {
      selection: '44109754$VS_OU@1.5_O',
      event: 'Z.Monaco - Z.Reims',
      market: 'Over/Under Goals',
      sign: 'Over (1.5) Goals',
      odd: 1.32,
    },
    {
      selection: '78765$S_1X2_X',
      event: 'Juventus - Napoli',
      market: '1X2',
      sign: 'Draw',
      odd: 3.2,
    },
    {
      selection: '99927928$S_1X2_1',
      event: 'Lazio - Roma',
      market: '1X2',
      sign: 'Lazio',
      odd: 2,
    },
  ],
}

export const mobileLitePM: PostMessage = {
  stake: 100,
  otp: fakeOTPs.nofail_d,
  source: 'mobile-lite',
  selections: [
    {
      selection: '44109754$VS_OU@1.5_O',
      event: 'Z.Monaco - Z.Reims',
      market: 'Over/Under Goals',
      sign: 'Over (1.5) Goals',
      odd: 1.32,
    },
    {
      selection: '78765$S_1X2_X',
      event: 'Juventus - Napoli',
      market: '1X2',
      sign: 'Draw',
      odd: 3.2,
    },
    {
      selection: '99927928$S_1X2_1',
      event: 'Lazio - Roma',
      market: '1X2',
      sign: 'Lazio',
      odd: 2,
    },
  ],
}

export const selfServicePM: PostMessage = {
  stake: 200,
  otp: fakeOTPs.nofail_d,
  source: 'self-service',
  selections: [
    {
      selection: '45285598$S#UnoXDue#UnoXDue_X',
      event: 'SJK Akatemia - FF Jaro',
      market: '1X2',
      sign: 'X',
      odd: 1.25,
    },
    {
      selection: '45351262$S#OverUnder25#OverUnder25_Over',
      event: 'Shrewsbury Town - Bristol Rovers',
      market: 'O/U 2.5 ',
      sign: 'Over',
      odd: 2.03,
    },
    {
      selection: '45329720$S#HalfTime#HalfTime_X',
      event: 'Lincoln City - Plymouth Argyle',
      market: 'Half Time',
      sign: 'X HT',
      odd: 2.09,
    },
  ],
}

export const desktopPM: PostMessage = {
  stake: 200,
  otp: fakeOTPs.nofail_d,
  source: 'desktop',
  selections: [
    {
      selection: '45285598$S#UnoXDue#UnoXDue_X',
      event: 'SJK Akatemia - FF Jaro',
      market: '1X2',
      sign: 'X',
      odd: 1.25,
    },
    {
      selection: '45351262$S#OverUnder25#OverUnder25_Over',
      event: 'Shrewsbury Town - Bristol Rovers',
      market: 'O/U 2.5 ',
      sign: 'Over',
      odd: 2.03,
    },
    {
      selection: '45329720$S#HalfTime#HalfTime_X',
      event: 'Lincoln City - Plymouth Argyle',
      market: 'Half Time',
      sign: 'X HT',
      odd: 2.09,
    },
  ],
}

export const oldMobilePM: PostMessage = {
  stake: 200,
  otp: fakeOTPs.nofail_d,
  source: 'old-mobile',
  selections: [
    {
      selection: '45285598$S#UnoXDue#UnoXDue_X',
      event: 'SJK Akatemia - FF Jaro',
      market: '1X2',
      sign: 'X',
      odd: 1.25,
    },
    {
      selection: '45351262$S#OverUnder25#OverUnder25_Over',
      event: 'Shrewsbury Town - Bristol Rovers',
      market: 'O/U 2.5 ',
      sign: 'Over',
      odd: 2.03,
    },
    {
      selection: '45329720$S#HalfTime#HalfTime_X',
      event: 'Lincoln City - Plymouth Argyle',
      market: 'Half Time',
      sign: 'X HT',
      odd: 2.09,
    },
  ],
}
