import styled from 'styled-components'

export const ToggleWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 14px;

  .simulate-toggle-text {
    color: ${(props) => props.theme.colours.neutrals.N030};
  }

  .simulate-toggle-checkbox {
    height: 0;
    width: 0;
    visibility: hidden;
  }

  .simulate-toggle-label {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 54px;
    height: 20px;
    background: grey;
    border-radius: 100px;
    position: relative;
    transition: background-color 0.2s;
  }

  .simulate-toggle-label .simulate-toggle-button {
    content: '';
    position: absolute;
    width: 22px;
    height: 22px;
    border-radius: 45px;
    transition: 0.5s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
  }

  .simulate-toggle-checkbox:checked + .simulate-toggle-label:before {
    content: 'ON';
    text-transform: uppercase;
    padding-left: 9px;
    font-weight: 500;
    color: ${(props) => props.theme.colours.surfaces.S050};
  }

  .simulate-toggle-checkbox:not(:checked) + .simulate-toggle-label:after {
    content: 'OFF';
    text-transform: uppercase;
    padding-left: 25px;
    font-weight: 500;
    color: ${(props) => props.theme.colours.neutrals.N040};
  }

  .simulate-toggle-checkbox:checked
    + .simulate-toggle-label
    .simulate-toggle-button {
    left: calc(75% - 2px);
    transform: translateX(-25%);
  }

  .simulate-toggle-label:active .simulate-toggle-button {
    /* width: 60px; */
  }
`
