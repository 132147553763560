import styled from 'styled-components'

export const StyledDisclaimer = styled.div`
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.58;
  p {
    margin: 0.8rem 0 2rem 0;
  }
  .brand {
    display: flex;
    align-items: center;

    .icon-logo {
      fill: #f59c0d;
    }
  }

  .btn-disclaimer {
    width: 100%;
    height: 44px;
    color: #fdb849;
    background-color: #515164;
    border-radius: 2px;
    box-shadow: 0 4px 4px -3px rgba(0, 0, 0, 0.5),
      inset 0 1px 0 0 rgba(255, 255, 255, 0.05);
    border: none;
    margin: 0;
    padding: 0;

    &:hover {
      cursor: pointer;
    }

    &:active {
      background-color: #373943;
      box-shadow: none;
    }
  }
`
