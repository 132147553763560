import { Football } from '../svgs'
import styled from 'styled-components'

export const ExploreHeading = () => {
  return (
    <Wrapper data-testid="exploreHeading">
      <Football width="16" height="16" viewBox="0 0 14 16" />
      <Heading>Top 15 Most Popular Bets</Heading>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  line-height: 16px;
  color: ${(props) => props.theme.colours.neutrals.N040};
  margin: 0 4px;
`

const Heading = styled.h2`
  margin: 0;
  font-weight: 500;
`
