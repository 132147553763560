import { useSelector } from 'react-redux'
import { selectReport } from '../../app/store/reports/reports.state'
import { Box, DateS, Info, Total, Message } from './report.style'
import { fixDateForAllBrowsers, toFixed } from '../../app/lib/utils/uiUtils'

export const ReportBox = () => {
  const report = useSelector(selectReport)

  const hasData = Object.values(report).some(
    (value) => value !== 0 && value !== null && value !== ''
  )

  if (!hasData) {
    return (
      <Message>
        <p>There is currently no information to display here.</p>
      </Message>
    )
  }
  const { localTime, localDate } = (() => {
    if (report.gen_date === null) return { localTime: '', localDate: '' }

    const fixedDate = fixDateForAllBrowsers(report.gen_date)
    const date = new Date(fixedDate)
    const localDate = date.toLocaleDateString()
    const localTime = date.toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    })

    return { localTime, localDate }
  })()

  return (
    <Box>
      <DateS>
        <span className="key">Last Updated:</span>
        <span>
          {localTime} {localDate}
        </span>
      </DateS>
      <Info>
        <span className="key">Turn Over:</span>
        <span>{report.total_stake}</span>
      </Info>
      <Info>
        <span className="key">Paid Out:</span>
        <span>{report.total_win}</span>
      </Info>
      <Info>
        <span className="key">Tickets:</span>
        <span>{report.number_bets}</span>
      </Info>
      <Total>
        <span className="key">Total Profit:</span>
        {report.total_profit && <span>{toFixed(report.total_profit, 2)}</span>}
      </Total>
    </Box>
  )
}
