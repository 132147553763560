import styled from 'styled-components'

export const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  /* max-width: 1024px; */
  min-width: 320px;
  margin: 0 auto;
  background: ${({ theme }) => theme.colours.surfaces.S000};
  z-index: 1009;
  overflow: hidden;

  .container {
    position: absolute;
    top: 50%;
    transform: translate(0%, -40%);
    text-align: center;
    line-height: 35px;
    color: #fff;
    overflow: hidden;
  }

  .print {
    font-size: 30px;
    font-weight: 700;
    margin: 0;
    width: min-content;
    text-transform: uppercase;
  }

  .info {
    margin: 0;
    font-size: 13px;
    font-weight: 500;
  }
`
