import { useSelector } from 'react-redux'
import { selectError, validatesErrorModal } from '../../app/store/app/app.state'
import { useAppDispatch } from '../../app/store/store'
import { Modal } from '../modal/modal'
import { Wrapper } from './errorModal.style'

export const ErrorModal = () => {
  const dispatch = useAppDispatch()
  const error = useSelector(selectError)
  const handleClick = () => dispatch(validatesErrorModal())

  return (
    <>
      {error && (
        <Modal>
          <Wrapper>
            <div className="message" data-testid="error-msg">
              {error?.message}
            </div>
            <button onClick={handleClick} className="btn">
              <span>OK Continue</span>
            </button>
          </Wrapper>
        </Modal>
      )}
    </>
  )
}
