import styled from 'styled-components'

export const Wrapper = styled.div`
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.58;
  text-align: center;

  .btn {
    color: white;
    background-color: #515164;
    box-shadow: none;
    border: 1px solid #2d2f34;
    width: 100%;
    height: 44px;
  }

  .message {
    margin: 0.8rem 0 2rem 0;
  }
`
