import React from 'react'
import { Flare } from '../flare/flare'
import { Goal } from '../goal/goal'
import { ScoreBar } from './scoreBar'
import { StatusBar } from './statusBar'
import { Item } from './gameItem.style'
import { SelectionResponse } from '../../app/api/types'
import { findMarketFromID } from '../../app/markets/marketUtils'
import { showOutcome, showMarket } from '../../app/lib/utils/uiUtils'
import { useItemState } from '../../app/hooks/useItemState'

interface IProps {
  selection: SelectionResponse
}
export const GameItem: React.FC<IProps> = ({ selection }) => {
  const marketName = findMarketFromID(selection.market_id)
  const { goal, flare, winning, won, score, halftimeResult } = useItemState(
    selection,
    marketName
  )
  const outcome = showOutcome(selection.selected_option, selection.event_name)
  const market = showMarket(marketName)

  return (
    <Item data-testid="game-item">
      {flare && <Flare />}
      {goal && <Goal />}
      <ScoreBar
        score={score}
        halftimeResult={halftimeResult}
        event={selection.event_name}
        won={won}
      />
      <StatusBar
        outcome={outcome}
        market={market}
        winning={winning}
        won={won}
      />
    </Item>
  )
}
