import styled, { css } from 'styled-components'

export const StyledCouponBet = styled.div<{ idx: number }>`
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%;
  margin: 0px;
  padding: 7px 0px;
  background-color: ${(props) => props.theme.colours.surfaces.S050};

  .coupon-bet {
    padding-bottom: 5px;
  }

  span {
    font-size: 12px;
    color: ${(props) => props.theme.colours.neutrals.N040};
    &.emphasis {
      color: ${(props) => props.theme.colours.neutrals.N030};
      font-size: 12px;
      font-weight: 500;
      margin-right: 5px;
    }
  }

  .results {
    text-align: end;
    .odd {
      font-size: 12px;
      font-weight: 400;
      color: ${(props) => props.theme.colours.neutrals.N030};
      padding-bottom: 5px;
    }

    span {
      font-size: 12px;
      color: ${(props) => props.theme.colours.neutrals.N040};
    }
  }

  ${({ idx }) =>
    idx > 0 &&
    css`
      border-top: 1px solid rgba(255, 255, 255, 0.2);
    `}
`
