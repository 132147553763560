import styled, { css } from 'styled-components'

export const Head = styled.div<{ expand: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  background-color: ${(props) => props.theme.colours.surfaces.S050};
  border-radius: 6px;
  transition: background-color 60ms linear 10ms;
  cursor: pointer;

  ${({ expand }) =>
    expand &&
    css`
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      transition: background-color 60ms ease-out;
    `};
`

export const Info = styled.div`
  flex: 1 1 auto;
  color: ${({ theme }) => theme.colours.neutrals.N040};
  font-size: 12px;
  padding: 8px;

  .market-name {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: ${({ theme }) => theme.colours.neutrals.N030};
  }
  .market {
    line-height: 16px;
  }

  .box {
    display: flex;
    align-items: center;

    .event {
      padding-left: 4px;
    }
  }
`

////BUTTON
export const ButtonWrapper = styled.div``

export const OddButton = styled.button<{ selected: boolean; expand: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  justify-content: center;
  padding: 15px 10px 18px 18px;
  background: ${({ theme }) => theme.colours.surfaces.S050O};
  border: none;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  background: ${({ selected, theme }) =>
    selected && theme.colours.actionsPrimary.AP050O};
  outline: none;
  cursor: pointer;
  width: 72px;

  ${({ expand }) =>
    expand &&
    css`
      margin-top: 4px;
      margin-right: 4px;
      margin-bottom: 4px;
      border-radius: 6px;
      padding: 11px 6px 14px 18px;
      width: 68px;
    `}

  /* &:hover {
    background-color: ${({ theme }) => theme.colours.information.RI090};
    cursor: pointer;
  } */

  .value {
    color: ${({ theme }) => theme.colours.actionsPrimary.AP050};
    font-weight: 900;
    font-size: 12px;
    line-height: 16px;
  }

  .add-icon {
    path {
      fill: ${({ theme }) => theme.colours.actionsPrimary.AP050};
    }
  }
`
