import { motion } from 'framer-motion'
import React from 'react'
import { useSelector } from 'react-redux'
import { TimeLine } from '../../app/lib/timeline/timeline'
import { GameSpeed, selectIsCashier } from '../../app/store/app/app.state'
import { selectGameFooterInfos } from '../../app/store/game/game.state'
import { Whistle } from '../svgs/index'

import { Container, TextDisplay } from './gameDisplay.styles'

export type GameDisplayProps = {
  speed: GameSpeed
  timeline: TimeLine
}
export const GameDisplay: React.FC<GameDisplayProps> = React.memo(
  ({ speed, timeline }) => {
    const isCashier = useSelector(selectIsCashier)
    const { bet_id } = useSelector(selectGameFooterInfos)

    if (
      (speed === 'turbo' && timeline?.clock > 0 && timeline?.clock < 45) ||
      (speed === 'turbo' && timeline?.clock > 45 && timeline?.clock < 90)
    ) {
      return (
        <Container>
          <Dots />
        </Container>
      )
    }

    const isFullTime = timeline?.display === 'FULLTIME'

    return (
      <Container>
        <TextDisplay display={timeline?.display} isCashier={isCashier}>
          {/* {isCashier ? 'RESULTS' : timeline?.display} */}
          {!isCashier && isFullTime && (
            <Whistle
              fill="#f59f14"
              height="24"
              width="24"
              viewBox="-3 -3 34 34"
            />
          )}

          {isCashier && isFullTime && (
            <>
              <Whistle
                fill="#f59f14"
                height="24"
                width="24"
                viewBox="-3 -3 34 34"
                style={{ margin: '0 6px' }}
              />
              <span className="info">Ticket No# {bet_id} &nbsp;</span>
            </>
          )}

          {timeline?.display}
        </TextDisplay>
      </Container>
    )
  }
)

export const Dots = () => {
  const isCashier = useSelector(selectIsCashier)
  return (
    <motion.div
      style={{
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '76px',
      }}
      animate="visible"
      initial="hidden"
      transition={{ ease: 'easeOut', duration: 1 }}
      variants={{
        visible: {
          opacity: 1,
          transition: {
            staggerChildren: isCashier ? 1.4 : 0.5,
          },
        },
        hidden: {
          opacity: 0,
        },
      }}
    >
      {[0, 0, 0, 0, 0, 0, 0].map((_, i) => (
        <motion.div
          key={`dot_${i}`}
          style={{
            width: 4,
            height: 4,
            backgroundColor: '#f59f14',
          }}
          variants={{
            visible: { opacity: 1 },
            hidden: { opacity: 0 },
          }}
        />
      ))}
    </motion.div>
  )
}
