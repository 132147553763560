import * as Sim from '../markets'
import { simulateMarkets } from './marketMap'

/**
 * Determine if a market is currently winning.
 * Uses the market map.
 * Uses partial application passing :
 * @param outcome simulate outcome partially applied
 * @param marketName simulate market
 */
export const winningBet =
  (outcome: Sim.Outcome, marketName: Sim.Market) =>
  (score: number[]): boolean | 'settled' =>
    simulateMarkets.get(marketName)?.logic(outcome, score) || false

/**
 * Retreive a market name from its id, sses the market map.
 * @param marketID as defined by simulate backend
 */
export const findMarketFromID = (marketID: number): Sim.Market => {
  const marketArray = Array.from(simulateMarkets.keys())
  const find = marketArray.find((m) => simulateMarkets.get(m)?.id === marketID)
  return find || ('' as Sim.Market)
}
