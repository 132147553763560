import styled from 'styled-components'

const sectionPadding = '0.75rem'
const sectionMarginBottom = '0.67rem'

export const ReceiptContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: -1;
`

export const ReceiptWrapper = styled.div`
  background: #ffffff;
  display: grid;
  visibility: hidden;
  padding: 0 4rem 0 2.1rem;
  span {
    font-size: 0.6rem;
  }
  #content,
  #watermark {
    grid-column: 1;
    grid-row: 1;
  }
  #content {
    z-index: 2;
  }
  #watermark {
    opacity: 0.1;
    transform: rotate(-45deg);
    z-index: 1;
    span {
      font-size: 5rem;
      text-align: center;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  hr {
    width: 100%;
    border: 0.5px solid black;
    font-size: 1rem;
  }
`

export const ReceiptHeader = styled.div`
  margin-bottom: ${sectionMarginBottom};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`
export const ReceiptInfo = styled.div`
  border: 2px solid #252525;
  padding: ${sectionPadding};
  margin-bottom: ${sectionMarginBottom};
  display: flex;
  flex-direction: column;
  line-height: 1rem;
  .al {
    align-items: flex-end;
  }
  .ticket_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .ticket_info_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        margin-right: 0.75rem;
      }
    }
    .ticket_info_right {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
  .result_info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .result_info_left {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        margin-right: 0.75rem;
      }
    }
    .result_info_right {
      display: flex;
      flex-direction: row;
      align-items: center;
      div {
        display: flex;
        flex-direction: column;
        margin-left: 0.75rem;
      }
    }
  }
`

export const ReceiptResults = styled.div`
  border: 1px solid #252525;
  border-radius: 4px;
  padding: ${sectionPadding};
  margin-bottom: ${sectionMarginBottom};
  .game {
    display: flex;
    flex-direction: column;
    /* line-height: 1rem; */
  }
  .game_bet {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: flex-end;
  }
  .game_result {
    display: flex;
    flex-direction: row;
  }
  .game_result_ht {
    font-weight: 100;
  }
  .bet {
    font-weight: 500;
  }
  .odd {
    font-weight: 700;
  }
  #dot {
    border-bottom: dotted 1px black;
    flex: 1 0 auto;
    margin: 0 2px 2px 2px;
  }
`
export const ReceiptStatus = styled.div`
  border: 2px solid #000000;
  padding: ${sectionPadding};
  display: flex;
  justify-content: space-between;
  span {
    font-size: 0.8rem;
  }
  .bold {
    font-weight: 700;
  }
`
export const BoldText = styled.span`
  font-size: 0.6rem;
  font-weight: 700;
`
