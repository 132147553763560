import { ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useCountDown } from '../../app/lib/count-down/useCountDown'
import { selectIsCashier } from '../../app/store/app/app.state'
import {
  selectFreeBets,
  selectNotification,
} from '../../app/store/betslip/betslip.selectors'
import {
  clicksOnFreeBet,
  // toggleFreeBetsDrawer,
} from '../../app/store/betslip/betslip.state'
import { useAppDispatch } from '../../app/store/store'
import { Gift, TIcons, NotifBorder, Checkthick } from '../svgs'
import { NotifWrapper } from './notificationItem.style'

const dynamicSVG = (name: TIcons): ReactNode => {
  const refs = {
    gift: <Gift width="24px" height="24px" viewBox="0 0 24 24" />,
    checkthick: <Checkthick width="24px" height="24px" viewBox="0 0 24 24" />,
    notifBorder: <NotifBorder width="24px" height="24px" viewBox="0 0 24 24" />,
    //...
  } as { [key in TIcons]: ReactNode }

  return refs[name] || <path />
}

type TNotif = {
  icon: TIcons
  msg: string
  color?: string
  date?: string
  hasBeenViewed?: boolean
}

export interface INotificationProps {
  notif: TNotif
  isToast?: boolean
}
export const NotificationItem: React.FC<INotificationProps> = ({
  notif,
  isToast,
}) => {
  const { icon, msg, color, hasBeenViewed, date } = notif
  const { btn } = useSelector(selectNotification)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const isCashier = useSelector(selectIsCashier)

  const freeBetsList = useSelector(selectFreeBets)
  const expiry = useCountDown(freeBetsList[0])

  const handleClickFreeBet = () => {
    if (freeBetsList.length === 1 && expiry?.output !== 'EXPIRED') {
      dispatch(clicksOnFreeBet(freeBetsList[0]))
      return
    }
    // dispatch(toggleFreeBetsDrawer())
    navigate('/mybets/freebets')
  }
  if (isCashier) return <div></div>

  return (
    <NotifWrapper color={color} isToast={isToast} data-testid="notifWrapper">
      <div className="content-box">
        <span className="icon" data-testid="icon">
          {dynamicSVG(icon)}
          {!hasBeenViewed && !isToast && (
            <span className="dot" data-testid="dot" />
          )}
        </span>
        <span className="msg">{msg}</span>
        {date && <span className="date">{date}</span>}
      </div>

      {btn && (
        <button onClick={handleClickFreeBet} className="btnMsg">
          {btn}
        </button>
      )}
    </NotifWrapper>
  )
}
