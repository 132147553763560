import { Dispatch } from '@reduxjs/toolkit'
import { useCallback, useEffect } from 'react'
import TagManager from 'react-gtm-module'
import { validatePostMessage } from '../adapter/postMessageValidator'
import { PostMessage, Source } from '../adapter/types'
import { ErrorResponse } from '../api/types'
import { getEnv, targetUrl } from '../envs'
import * as pm from '../mocks/postMessageMock'
import { bootUp, createError, updateIsCashier } from '../store/app/app.state'
import { useAppDispatch } from '../store/store'

export function useStartApp() {
  const dispatch = useAppDispatch()

  const handleMessage = useCallback(
    (messageEvent: MessageEvent) => {
      const validMessageEvent =
        messageEvent?.data &&
        typeof messageEvent.data === 'string' &&
        messageEvent.data !== 'Simulate has loaded'

      if (validMessageEvent) {
        try {
          const postMessage = validatePostMessage(JSON.parse(messageEvent.data))
          dispatch(bootUp(postMessage))
        } catch (error) {
          dispatch(createError(error as ErrorResponse))
        }
      }
    },
    [dispatch]
  )

  useEffect(() => {
    parent.postMessage('Simulate has loaded', '*')

    // google tag manager
    TagManager.initialize({
      gtmId: 'GTM-P8L9JMX',
    })

    // dev if you can !!
    devEnv(dispatch)

    window.addEventListener('message', handleMessage)
    return () => {
      window.removeEventListener('message', handleMessage)
    }
  }, [dispatch, handleMessage])
}

function devEnv(dispatch: Dispatch<any>) {
  const host = process.env.REACT_APP_HOST
  const { currency, minStake, maxStake, maxWin } = getEnv(host as Source)
  const env = process.env.NODE_ENV
  const url = targetUrl()

  if (env === 'development') {
    console.log(
      `%cenv: ${url} ${currency} ${minStake} ${maxStake} ${maxWin}`,
      'color: #33649c; font-size: 14px;'
    )

    console.log(
      '%c' + env + ' environment with ' + host + ' mock',
      'color: #9640c7; font-size: 14px;'
    )

    const processPM = (postMessage: PostMessage) => {
      dispatch(bootUp(postMessage))
      console.log(
        `%c ${host} mock: `,
        'color: #339c8e; font-size: 14px;',
        postMessage
      )
    }

    const isCashier = (postMessage: PostMessage) => {
      processPM(postMessage)
      dispatch(updateIsCashier(true))
    }

    const pmMock: { [key: string]: () => void } = {
      'new-mobile': () => processPM(pm.newMobilePM),
      'new-desktop': () => processPM(pm.newDesktopPM),
      'mobile-lite': () => processPM(pm.mobileLitePM),
      desktop: () => processPM(pm.desktopPM),
      'old-mobile': () => processPM(pm.oldMobilePM),
      'self-service': () => processPM(pm.selfServicePM),
      cashier: () => isCashier(pm.selfServicePM),
    }

    return host
      ? pmMock[host]()
      : console.log('%cunknown dev env: ', 'color: orange; font-size: 14px;')
  }
}
