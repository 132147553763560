import styled from 'styled-components'
import { FilterList } from '../../app/store/mygames/mygames.state'

export const FilterWrapper = styled.div`
  /* position: fixed; */
  width: 100%;
  max-width: 1024px;
  background-color: #17181c;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 7px 4px 5px 0; */
  padding: 15px 0;
  z-index: 5;
  /* filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.3)); */
`

export const FilterContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 150px;
  height: 25px;
  border-radius: 50px;
  border: solid 1px #272930;
  background-color: #101113;
`

export const SelectorUnderlay = styled.span<{ filter: FilterList }>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 46px;
  height: 25px;
  border: solid 1px #272930;
  border-radius: 50px;
  background-color: #595b6a;

  transform: ${({ filter }) => {
    switch (filter) {
      case 'won':
        return 'translateX(-52px)'
      case 'lost':
        return 'translateX(0px)'
      case 'all':
        return 'translateX(52px)'
      default:
        return 'translateX(52px)'
    }
  }};
  transition: transform 200ms ease-out;
`

export const Selector = styled.span<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: white;
  font-weight: 700;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: ${({ selected }) => (selected ? '1' : '0.6')};
  transition: opacity 200ms ease-out;
  cursor: pointer;
`
