import { Outlet } from 'react-router-dom'
import { useSource } from '../../app/hooks/useSource'
import { StyledPage } from '../../style/styles'
import { ErrorModal } from '../error-modal/errorModal'
import { MyBetsNav } from '../myBets-nav/myBetsNav'
import { Receipt } from '../receipt/receipt'

export const MyBetsPage = () => {
  const { isCashier } = useSource()

  return (
    <>
      <StyledPage>
        {isCashier ? <Receipt /> : undefined}
        <MyBetsNav />

        <Outlet />
      </StyledPage>
      <ErrorModal />
    </>
  )
}
