import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  /* width: 80px; */
  max-width: 100%;
  height: 24px;
  padding: 2px 0;
  margin: 7px;
  background-color: ${({ theme }) => theme.colours.surfaces.S090};
  color: ${({ theme }) => theme.colours.actionsPrimary.AP050};
  border-radius: 4px;
  overflow: hidden;
`

export const TextDisplay = styled.div<{
  display: string
  isCashier: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Lcdphone';
  width: 100%;
  padding: 0 16px;
  height: 24px;
  color: #f59c0d;
  font-size: ${({ display }) => (display === 'FULLTIME' ? '10px' : '12px')};
  font-size: ${({ isCashier }) => isCashier && '12px'};
  background-color: #101213;
  .info {
    font-family: Lcdphone;
    font-size: 10px;
    line-height: 12px;
  }

  svg path {
    fill: ${({ theme }) => theme.colours.actionsPrimary.AP050};
  }
`
