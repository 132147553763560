import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { BetListSelection } from '../../api/types'
import { RootState } from '../store'

export type TReceiptData = {
  bet_id: string
  client_id: string
  stake: number
  win: number
  potencial_win: number
  total_odds: number
  placement_date: string
  original: boolean
  selections: BetListSelection[]
  status: number
}

export type TStatus = 'start' | 'end' | 'idle'

export type ReceiptSlice = {
  data: TReceiptData
  status: TStatus
}

export const initialState: ReceiptSlice = {
  data: {
    bet_id: '',
    client_id: '',
    stake: 0,
    win: 0,
    total_odds: 0,
    placement_date: '',
    original: false,
    selections: [],
    status: 0,
    potencial_win: 0,
  },
  status: 'idle',
}

export const receiptSlice = createSlice({
  name: 'receipt',
  initialState,
  reducers: {
    setReceiptData: (state, { payload }: PayloadAction<TReceiptData>) => {
      state.data = payload
    },
    setReceiptStatus: (state, { payload }: PayloadAction<TStatus>) => {
      state.status = payload
    },
  },
})

export default receiptSlice.reducer

// actions --------------------------------------------------------
export const { setReceiptData, setReceiptStatus } = receiptSlice.actions

// thunks --------------------------------------------------------

// Selectors -------------------------------------------------------
export const selectReceiptData = (state: RootState) => state.receipt.data
export const selectReceiptStatus = (state: RootState) => state.receipt.status
