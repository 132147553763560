import { useSelector } from 'react-redux'
import { selectCanPrint } from '../../app/store/app/app.state'
import { selectIsLoader } from '../../app/store/game/game.state'
import { Spinner } from '../spinner/spinner'
import { Wrapper } from './loader.style'

export const Loader = () => {
  const canPrint = useSelector(selectCanPrint)
  const isLoader = useSelector(selectIsLoader)

  return (
    <>
      {isLoader && (
        <Wrapper data-testid="loader">
          <Spinner height="240px" size="240px" thick={2} color=" #F6B52D" />
          <div className="container">
            <p className="print">
              {canPrint ? 'Printing Ticket' : 'Simulating'}
            </p>
            <p className="info">Please Wait</p>
          </div>
        </Wrapper>
      )}
    </>
  )
}
