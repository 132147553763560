import { Wrapper } from './toastNotification.style'
import { AnimatePresence } from 'framer-motion'
import { useSelector } from 'react-redux'
import { selectNotification } from '../../app/store/betslip/betslip.selectors'
import { NotificationItem } from '../notificationItem/notificationItem'

export const ToastNotification = () => {
  const notif = useSelector(selectNotification)

  return (
    <AnimatePresence>
      {notif.msg && (
        <Wrapper
          variants={{
            out: { y: -120, transition: { delay: 0 } },
            in: { y: 0, transition: { duration: 0.3 } },
          }}
          initial="out"
          animate="in"
          exit="out"
        >
          <NotificationItem isToast notif={notif} />
        </Wrapper>
      )}
    </AnimatePresence>
  )
}
