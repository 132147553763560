import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { SimBet, Source, TPopularEvent } from '../../adapter/types'
import { AppThunk, RootState } from '../store'
import * as httpClient from '../../api/httpClient'
import { createError } from '../app/app.state'
import { APIStatus, ErrorResponse } from '../../api/types'
import { getSport, SportsCode } from '../../adapter/sportMap'
import { simulateMarkets } from '../../markets/marketMap'
import * as Sim from '../../markets'
import { getSymbolToUse } from '../../adapter/adapter'
import { findMarketFromID } from '../../markets/marketUtils'

export type SportsBookState = {
  status: APIStatus
  book: SimBet[]
  active_markets: number[]
  selectedMarket: string | number
}

export const initialState: SportsBookState = {
  status: 'idle',
  book: [] as SimBet[],
  active_markets: [] as number[],
  selectedMarket: 'explore',
}

const sportsBookSlice = createSlice({
  name: 'sportsbook',
  initialState,
  reducers: {
    setStatus(state, { payload }: PayloadAction<APIStatus>) {
      state.status = payload
    },
    createBook(state, { payload }: PayloadAction<SimBet[]>) {
      state.book = payload
      state.status = 'idle'
    },
    setActiveMarkets(state, { payload }: PayloadAction<number[]>) {
      state.active_markets = payload
    },
    setSelectedMarket(state, { payload }: PayloadAction<string | number>) {
      state.selectedMarket = payload
    },
  },
})

export default sportsBookSlice.reducer

export const { setStatus, createBook, setActiveMarkets, setSelectedMarket } =
  sportsBookSlice.actions

export const fetchSportsBook = (): AppThunk => async (dispatch, getState) => {
  const state = getState()
  const { sessionID } = state.app
  const { selectedMarket } = state.sportsbook

  dispatch(setStatus('loading'))

  let odds

  try {
    if (typeof selectedMarket === 'number') {
      odds = await httpClient.getExploreMarket(sessionID, selectedMarket)
    } else {
      odds = await httpClient.getExploreSport(sessionID, 'soccer')
    }

    let symbol = ''
    let source: Source = ''

    if (odds?.active_markets) {
      dispatch(setActiveMarkets(odds.active_markets))
    }

    if (odds.popular_events === null) {
      dispatch(createBook([]))
    } else {
      const mapToSimBet = (e: TPopularEvent): SimBet => {
        // get the symbol to split on
        // on the explore page we can have bets from both logispin and iSolutions
        // we can use desktop for iSolutions bets
        // and new-mobile for Logispin bets
        e.code.includes('#') ? (source = 'desktop') : (source = 'new-mobile')
        symbol = getSymbolToUse(source)

        // extract matchId and sport head, MKT, outcome
        const [head, MKT] = e.code.split(symbol)
        const [, sport] = head.split('$')
        // find which sport map to use
        const sportMap = getSport(source, sport as SportsCode)
        if (!sportMap) {
          return {} as SimBet
        }
        const simulateMarket = sportMap.marketMap.get(MKT) // find if there is a matching market in the map
        const supported = sportMap.marketMap.has(MKT) // find if market is supported
        const simMarketID =
          simulateMarkets.get(simulateMarket || ('' as Sim.Market))?.id || 0 // used for backend call

        return {
          b9jBet: e.code,
          matchID: e.event_id,
          odd_id: e.odd_id,
          sport: e.sport_id, // placeholder
          odd: e.odd,
          event: e.event_name,
          market: findMarketFromID(e.market_id),
          simMarketID: simMarketID,
          outcome: e.selected_option,
          simOutcome: e.selected_option,
          supported: supported,
          blocking: false,
          stats: e?.stats,
        }
      }

      dispatch(
        createBook(
          odds.popular_events
            .map(mapToSimBet)
            .filter((value: any) => Object.keys(value).length !== 0)
        )
      )
    }
  } catch (e) {
    dispatch(createError(e as ErrorResponse))
  }
}

export const selectSportsBook = (state: RootState) => state.sportsbook.book
export const selectSportsBookStatus = (state: RootState) =>
  state.sportsbook.status as APIStatus
export const selectActiveMarkets = (state: RootState) =>
  state.sportsbook.active_markets
export const selectSelectedMarket = (state: RootState) =>
  state.sportsbook.selectedMarket
