/* eslint-disable no-throw-literal */
import { PostMessage } from './types'

export const validatePostMessage = (pm: PostMessage): PostMessage => {
  // check all post message properties
  const validpm =
    Object.prototype.hasOwnProperty.call(pm, 'otp') &&
    Object.prototype.hasOwnProperty.call(pm, 'selections') &&
    Object.prototype.hasOwnProperty.call(pm, 'stake')

  if (validpm) {
    // check all selections

    const filteredPM = {
      ...pm,
      selections: pm.selections.filter(
        (selection) =>
          Object.prototype.hasOwnProperty.call(selection, 'selection') &&
          Object.prototype.hasOwnProperty.call(selection, 'event') &&
          Object.prototype.hasOwnProperty.call(selection, 'market') &&
          Object.prototype.hasOwnProperty.call(selection, 'sign') &&
          Object.prototype.hasOwnProperty.call(selection, 'odd')
      ),
    }

    // cap the number of selections
    if (filteredPM.selections.length > 40) {
      throw { message: 'The maximum number of selection is 40.' }
    }

    return filteredPM
  }

  throw { message: 'invalid post message' }
}
