import { motion } from 'framer-motion'
import React, { ReactNode, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { distinctUntilChanged } from 'rxjs/operators'
import { useSource } from '../../app/hooks/useSource'
import { TimeLine, timeline$ } from '../../app/lib/timeline/timeline'
import { selectMenu } from '../../app/store/betslip/betslip.selectors'
import {
  selectGameDataAccStatus,
  selectGameSelections,
} from '../../app/store/game/game.state'
import { PageWrapper } from '../../style/styles'
import { GameBoost } from '../boost/boost'
import { CountDown } from '../count-down/countDown'
import { GameFooter } from '../game-footer/gameFooter'
import { GameHeader } from '../game-header/gameHeader'
import { GameItem } from '../game-item/gameItem'
import { Receipt } from '../receipt/receipt'
import { GameWrapper, Wrapper } from './game.style'

export const GameLayout = () => {
  const { isCashier } = useSource()

  return (
    <PageWrapper>
      {isCashier ? <Receipt /> : undefined}
      <GameAnimations />
      <Game>
        <GameHeader />
        <GameContainer />
      </Game>
      <GameFooter />
    </PageWrapper>
  )
}

const Game: React.FC<{ children: ReactNode }> = ({ children }) => {
  const slideUI = useSelector(selectMenu)
  return (
    <motion.div
      initial="show"
      animate={slideUI ? 'show' : 'hide'}
      // variants={{ show: { y: 0 }, hide: { y: -48 } }}
      transition={{ ease: 'linear', duration: 0.2 }}
    >
      {children}
    </motion.div>
  )
}

export const GameContainer: React.FC = () => {
  const accountStatus = useSelector(selectGameDataAccStatus)
  const selections = useSelector(selectGameSelections)

  return (
    <GameWrapper>
      {accountStatus !== -1 &&
        selections?.map((selection, idx) => (
          <GameItem key={`game-item_${idx}`} selection={selection} />
        ))}
    </GameWrapper>
  )
}

export const GameAnimations = () => {
  const [state, setState] = useState<TimeLine>()

  useEffect(() => {
    const sub = timeline$
      .pipe(
        distinctUntilChanged(
          (x, y) => x.boost === y.boost && x.countDown === y.countDown
        )
      )
      .subscribe(setState)
    return () => sub.unsubscribe()
  }, [])

  if (state?.boost || state?.countDown)
    return (
      <>
        <Wrapper>
          <GameBoost display={state.boost} />
          <CountDown display={state.countDown} />
        </Wrapper>
      </>
    )

  return <></>
}
