import { useDispatch } from 'react-redux'
import { SimBet } from '../../app/adapter/types'
import { Warning, Remove } from '../svgs/index'
import { removeSelection } from '../../app/store/betslip/betslip.state'
import {
  Unsupported,
  BetSlipItem,
  Cartouche,
  Delete,
  Item,
  Bet,
  Odd,
} from './selectionItem.style'
import {
  showOutcome,
  titleCaseSentence,
  truncateString,
} from '../../app/lib/utils/uiUtils'

interface ISelectionItem {
  bet: SimBet
}
export const SelectionItem: React.FC<ISelectionItem> = ({ bet }) => {
  return (
    <BetSlipItem data-testid="selection-item">
      {!bet.supported ? (
        <UnsupportedSelection />
      ) : (
        <ActiveSelection bet={bet} />
      )}
    </BetSlipItem>
  )
}

interface IActiveSelection {
  bet: SimBet
}
export const ActiveSelection: React.FC<IActiveSelection> = ({ bet }) => {
  const { simOutcome, outcome, event, market, odd, blocking, b9jBet } = bet
  const dispatch = useDispatch()

  const deleteSelection = (id: string) => {
    dispatch(removeSelection(id))
  }
  const displayOutcome = showOutcome(simOutcome || (outcome as any), event)
  return (
    <>
      {blocking && (
        <Cartouche>
          <Warning width="17" height="17" viewBox="0 0 24 24" />
        </Cartouche>
      )}
      <Item warning={blocking}>
        <Bet>
          <div className="title">
            <span className="title-outcome">
              {truncateString(displayOutcome, 28)}
            </span>
            <span className="title-market">
              &nbsp;{truncateString(market, 22)}
            </span>
          </div>
          <div className="event">
            <span className="event-match">{titleCaseSentence(event)}</span>
          </div>
        </Bet>
        <Odd data-testid="betItem-odd">{odd}</Odd>
      </Item>
      <Delete
        onClick={() => deleteSelection(b9jBet)}
        warning={blocking}
        data-testid="remove-bet"
      >
        <Remove width="17" height="17" viewBox="0 0 24 24" />
      </Delete>
    </>
  )
}

export const UnsupportedSelection = () => (
  <Unsupported data-testid="unsupported">
    This betting market is not supported by simulate yet
    <br />
    It will not be included in your bet
  </Unsupported>
)
