import styled from 'styled-components'
import { TReportRange, Range } from '../../app/store/reports/reports.state'

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  top: 40px;
  padding: 0 8px;
  align-items: center;
`

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 16px;
  margin: 8px 0px;
  background: #515164;
  border-radius: 3px;
  min-width: 308px;
  max-width: 320px;
`

export const Info = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  color: #fff;
  width: 100%;
  margin: 5px 0;
  padding: 4px 0;
  span {
    font-size: 11px;
    &.key {
      opacity: 0.8;
    }
  }
`

export const DateS = styled(Info)`
  padding-top: 16px;
  border-bottom: 1px solid rgba(196, 196, 196, 0.5);
`
export const Total = styled(Info)`
  border-top: 1px solid #d9d9d9;
  padding-top: 8px;
  padding-bottom: 25px;
  span {
    font-size: 16px;
  }
`

export const FilterWrapper = styled.div`
  width: 100%;
  max-width: 320px;
  background-color: #17181c;
  display: flex;
  justify-content: flex-end;
  padding: 7px 4px 5px 0;
  z-index: 5;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.3));
`

export const FilterContainer = styled.div`
  width: 225px;
  height: 20px;
  border-radius: 14px;
  border: solid 1px #272930;
  background-color: #101113;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`

export const SelectorUnderlay = styled.span<{
  range: TReportRange
}>`
  position: absolute;
  display: inline-block;
  width: 75px;
  height: 20px;
  border: solid 1px #272930;
  border-radius: 11.5px;
  background-color: #515164;
  transform: ${({ range }) => {
    switch (range) {
      case Range.LASTSEVENDAYS:
        return 'translateX(-75px)'
      case Range.YESTERDAY:
        return 'translateX(0px)'
      case Range.TODAY:
        return 'translateX(75px)'
      default:
        return 'translateX(75px)'
    }
  }};
  transition: transform 200ms ease-out;
`

export const Selector = styled.span<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  color: white;
  height: 100%;
  width: 100%;
  z-index: 2;
  opacity: ${({ selected }) => (selected ? '1' : '0.6')};
  transition: opacity 200ms ease-out;
  cursor: pointer;
`
export const Message = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 30px 0 45px 0;
  padding-bottom: 120px;
  p {
    color: white;
    padding: 12px;
  }
`
