import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { selectIsCashier } from '../../app/store/app/app.state'
import {
  handleFreeRebet,
  selectHasFreeRebet,
  startGame,
} from '../../app/store/game/game.state'
import { useAppDispatch } from '../../app/store/store'
import ConfettiComponent from '../confetti/confetti'
import { Spinner } from '../spinner/spinner'
import { Rebet } from '../svgs'
import { Ticket, Wrapper, Button } from './freeBetTicket.style'

export const FreeBetTicket = () => {
  const [isLoading, setLoading] = useState(true)
  const dispatch = useAppDispatch()
  const hasFreeRebet = useSelector(selectHasFreeRebet)
  const isCashier = useSelector(selectIsCashier)

  useEffect(() => {
    if (hasFreeRebet) {
      setTimeout(() => setLoading(false), 2000)
    }
  }, [hasFreeRebet])

  return (
    <>
      {hasFreeRebet && (
        <Wrapper data-testid="freeBetTicket">
          <ConfettiComponent />

          <Ticket asset="/assets/vector.png" className="kk">
            <div className="icon-rebet">
              <Rebet width="82" height="52" viewBox="0 0 24 24" />
            </div>
            <div className="heading_won">YOU WON A</div>
            <div className="heading_rebet">FREE REBET!</div>
            <div className="heading_info">Pay Once Play Twice</div>

            <Button
              disabled={isLoading}
              onClick={() => {
                setLoading(true)
                dispatch(handleFreeRebet(false))
                dispatch(startGame(isCashier))
              }}
              data-testid="tertiary-btn"
            >
              {isLoading ? (
                <Spinner size="20px" height="20px" color="#F6B52D" />
              ) : (
                'Ok, Continue'
              )}
            </Button>
          </Ticket>
        </Wrapper>
      )}
    </>
  )
}
