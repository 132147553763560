import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 14px 12px 14px 8px;

  .heading {
    font-size: 10px;
  }

  .winAmount {
    font-weight: 500;
    color: ${(props) => props.theme.colours.actionsPrimary.AP050};
  }
`
