import { useSelector } from 'react-redux'
import { selectIsCashier, selectSource } from '../store/app/app.state'

export const useSource = () => {
  const isCashier = useSelector(selectIsCashier)
  const source = useSelector(selectSource)

  return {
    isCashier,
    source,
    isCashierAndSelfService: isCashier || source === 'self-service',
  }
}
