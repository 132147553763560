import { Source } from '../adapter/types'

export const nigeria = (source?: Source) => {
  return {
    maxWin: 10000000,
    minStake: source === 'self-service' ? 100 : 50,
    maxStake: 2000000,
    currency: '₦',
  }
}

export function getEnv(source?: Source) {
  return nigeria(source)
}

export function targetUrl() {
  switch (process.env.REACT_APP_BUILD_TARGET) {
    case 'stg':
      return 'https://api.simulate.stg.novafutur.com'
    case 'dev':
      return 'https://api.simulate.dev.novafutur.com'
    case 'prod':
      return 'https://api.simulate.novafutur.com'
    case 'cert':
      return 'https://api.simulate.cert.novafutur.com'
    default:
      return 'https://dev-simulate.appspot.com'
  }
}
